export const ACTION_STORE_CATEGORIES_REQUEST = 'ACTION_STORE_CATEGORY_REQUEST'
export const ACTION_STORE_CATEGORIES_SUCCESS = 'ACTION_STORE_CATEGORY_SUCCESS'
export const ACTION_STORE_CATEGORIES_FAIL = 'ACTION_STORE_CATEGORY_FAIL'

export const ACTION_CREATE_STORE_CATEGORY_REQUEST = 'ACTION_CREATE_STORE_CATEGORY_REQUEST'
export const ACTION_CREATE_STORE_CATEGORY_SUCCESS = 'ACTION_CREATE_STORE_CATEGORY_SUCCESS'
export const ACTION_CREATE_STORE_CATEGORY_FAIL = 'ACTION_CREATE_STORE_CATEGORY_FAIL'

export const ACTION_CREATE_STORE_SUBCATEGORY_REQUEST = 'ACTION_CREATE_STORE_SUBCATEGORY_REQUEST'
export const ACTION_CREATE_STORE_SUBCATEGORY_SUCCESS = 'ACTION_CREATE_STORE_SUBCATEGORY_SUCCESS'
export const ACTION_CREATE_STORE_SUBCATEGORY_FAIL = 'ACTION_CREATE_STORE_SUBCATEGORY_FAIL'

export const ACTION_EDIT_STORE_CATEGORY_REQUEST = 'ACTION_EDIT_STORE_CATEGORY_REQUEST'
export const ACTION_EDIT_STORE_CATEGORY_SUCCESS = 'ACTION_EDIT_STORE_CATEGORY_SUCCESS'
export const ACTION_EDIT_STORE_CATEGORY_FAIL = 'ACTION_EDIT_STORE_CATEGORIES_FAIL'

export const ACTION_DELETE_STORE_CATEGORY_REQUEST = 'ACTION_DELETE_STORE_CATEGORY_REQUEST'
export const ACTION_DELETE_STORE_CATEGORY_SUCCESS = 'ACTION_DELETE_STORE_CATEGORY_SUCCESS'
export const ACTION_DELETE_STORE_CATEGORY_FAIL = 'ACTION_DELETE_STORE_CATEGORY_FAIL'

export const ACTION_UPDATE_CATEGORY_TREE_REQUEST = 'ACTION_UPDATE_CATEGORY_TREE_REQUEST'
export const ACTION_UPDATE_CATEGORY_TREE_SUCCESS = 'ACTION_UPDATE_CATEGORY_TREE_SUCCESS'
export const ACTION_UPDATE_CATEGORY_TREE_FAIL = 'ACTION_UPDATE_CATEGORY_TREE_FAIL'

export const ACTION_UPLOAD_FILE_REQUEST = 'ACTION_UPLOAD_FILE_REQUEST'
export const ACTION_UPLOAD_FILE_SUCCESS = 'ACTION_UPLOAD_FILE_SUCCESS'
export const ACTION_UPLOAD_FILE_FAIL = 'ACTION_UPLOAD_FILE_FAIL'
