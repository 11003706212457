import {
	IonButton,
	IonButtons,
	IonCard,
	IonCheckbox,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonItemDivider,
	IonImg,
	IonLabel,
	IonList,
	IonLoading,
	IonModal,
	IonPage,
	IonPopover,
	IonRadio,
	IonRadioGroup,
	IonRow,
	IonSelect,
	IonText,
	IonTextarea,
	IonTitle,
	IonToast,
	IonToolbar,
	isPlatform,
} from '@ionic/react';
// import './Add.css'
import React, { useEffect, useRef, useState } from 'react';
import { Camera, CameraResultType } from '@capacitor/camera';
import {
	arrowBack as arrowBackIcon,
	informationCircle as informationCircleIcon,
	addOutline as addOutlineIcon,
	close as closeIcon,
	closeOutline,
	removeCircle as removeCircleIcon,
	addCircle as addCircleIcon,
	caretDown as caretDownIcon,
} from 'ionicons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessToken } from '../services/auth/userLocalStorage';
import { getStoresCategory } from '../actions/categoryAction';
import { createProduct } from '../actions/productActions';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import { ObjectUnsubscribedError } from 'rxjs';
import { API_ROOT } from '../constants/global';

async function savePicture(file) {
	if (!file) return '';
	const formData = new FormData();
	formData.append('file', file);
	try {
		const response = await fetch(`${API_ROOT}upload/product-image`, {
			method: 'POST',
			body: formData,
		});
		const data = await response.json();
		return data.platform_path;
	} catch (error) {
		return '';
	}
}

// const KeyCodes = {
//   comma: 188,
//   enter: [10, 13],
// };

// const delimiters = [...KeyCodes.enter, KeyCodes.comma];

// const placeholderlist = {
// 	Color: 'eg. Red, Blue, Green',
// 	Size: 'eg. Small, Medium, Large',
// 	Material: 'eg. Cotton, Wool, Polyester',
// 	Weight: 'eg. 500gm, 1Kg',
// 	'Add yours': 'Add yours',
// };
const AddOrder = (props: any) => {
	const [loading, setLoading] = useState(false);
	const [ordercreate, setOrderCreate] = useState(false);

const {reloadOrders} = props;

	// const [showModal, setShowModal] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [toastMessage, setToastMessage] = useState('');

	const dispatch = useDispatch();
	// const { currentUser } = useSelector((state: any) => state.currentUser);
	// const { fetchCategoriesLoading, categories, fetchCategoriesError } =
	// 	useSelector((state: any) => state.categories);
	// const { createCategoryLoading, createCategory, createCategoryError } =
	// 	useSelector((state: any) => state.categories);

	const { createProductLoading, createProductMessage, createProductError } =
		useSelector((state: any) => state.createProductData);

	// var categoryFromProps;
	// if (props && props.location && props.location.state) {
	// 	categoryFromProps = props.location.state;
	// }
	// console.log(createProductMessage, ' createProductMessage');
	// console.log(createProductError, ' createProductError');
	const history = useHistory();

	//states to store form data
	const [productName, setProductName] = useState<any>('');
	const [productStatus, setProductStatus] = useState('active');
	const [weight, setWeight] = useState<string>();
	const [servicePrice, setServicePrice] = useState<string>();
	// TODO:
	const [dimension, setDimension] = useState<string>('');
	const [helpText, setHelpText] = useState<string>('');
	const [internalNotes, setInternalNotes] = useState<string>('');

	//states to check empty or not
	const [isProductNameEmpty, setIsProductNameEmpty] = useState<boolean>(false);
	const [isServicePriceEmpty, setIsServicePriceEmpty] =
		useState<boolean>(false);
	const [isImageEmpty, setIsImageEmpty] = useState<boolean>(false);
	const [isWeightEmpty, setIsWeightEmpty] = useState<boolean>(false);
	const [isDimensionEmpty, setIsDimensionEmpty] = useState<boolean>(false);
	const [isHelpTextEmpty, setIsHelpTextEmpty] = useState<boolean>(false);
	const [isInternalNotesEmpty, setIsInternalNotesEmpty] =
		useState<boolean>(false);

	//states to validate
	const [isServicePriceValidate, setIsServicePriceValidate] =
		useState<boolean>(true);
	const [isImageValidate, setIsImageValidate] = useState<boolean>(true);
	const [isWeightValidate, setIsWeightValidate] = useState<boolean>(true);
	const [selectedBusiness, setSelectedBusiness] = useState(null);
    const [products, setProducts] = useState([]);
    const [showModal, setShowModal] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [selectedQuantities, setSelectedQuantities] = useState({});
	const [platformPath, setPlatformPath] = useState<any>('');
	const [businessData, setBusinessData] = useState([]);
	const [error, setError] = useState(null);

	const fileInputRef = useRef<HTMLInputElement>();
	const [pictureUrl, setPictureUrl] = useState('/assets/placeholder.svg');


	const [updateFlagadd, setUpdateFlagadd] = useState(false);
    const handleUpdateItem = () => {
      // Logic to update the item
      // After updating the item, set the update flag in localStorage
      localStorage.setItem('updateFlagadd', 'true');
      setUpdateFlagadd(true);
    };
    // useEffect to listen for changes in the update flag
    useEffect(() => {
      const reloadNeeded = localStorage.getItem('updateFlagadd');
      if (reloadNeeded) {
        // If updateFlag is true, reload the page
        history.push('/app/orders/act')
        window.location.reload();
        // After reloading, remove the update flag from localStorage
        localStorage.removeItem('updateFlagadd');
      }
    }, [updateFlagadd]); // This effect runs whenever updateFlag changes

	//working correct modal ---saubhagya
	useEffect(() => {
		const fetchBusinessData = async () => {
		  try {
			setLoading(true);
			const response = await fetch('https://api.citylinencare.com/order-init/businesses', {
			  headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${getAccessToken().token}`,
			  },
			});

			const data = await response.json();
			if (data && data.data) {
			  setBusinessData(data.data);
			  console.log("hello this is 1 and this is for the list in modal");
			  console.log(data.data)

			} else {
			  setError(data.message || 'Unexpected response');
			  console.log('unexpected');

			}
		  } catch (error) {
			setError('Error fetching business data: ' + error.message);
		  } finally {
			setLoading(false);
		  }
		};
	  
		fetchBusinessData();
	  }, []); 



	  useEffect(() => {
		const fetchProducts = async () => {
		  if (selectedBusiness) {
			console.log('selectedBusiness ',selectedBusiness)
			setLoading(true);

			try {
			  const response = await fetch(`https://api.citylinencare.com/order-init/products?businessId=${selectedBusiness.id}`, {
				headers: {
					"Content-Type": "application/json",
					authorization: `Bearer ${getAccessToken().token}`,
				},
			  });
	  
			  if (response.ok) {
				const data = await response.json();
				if (data && data.data) {
				//	console.log('selected-products',data)
				  setProducts(data.data);
				} 
			  } else {
				console.error('Error fetching products. HTTP status:', response.status);
			  }
			} catch (error) {
			  console.error('Error fetching products:', error.message);
			}finally {
				setLoading(false);
			  }
		  }
		};
	  
		fetchProducts();
	  }, [selectedBusiness]);


	  
	  const handleBusinessChange = (selectedBusinessId) => {
		// Find the selected business from the businessData array  
		const selectedBusiness = businessData.find((business) => business.id === selectedBusinessId);
	  		setSelectedBusiness(selectedBusiness);
	  };

	  const [showhead,setShowHead]=useState(false);
	  const handleBusinessClick = (business) => {
		handleBusinessChange(business.id);
		setShowModal(false); 
		setShowHead(true);
	  };
	
	  const handleQuantityChange = (e, productId) => {
		const quantity = parseInt(e.detail.value, 10);
		setSelectedQuantities({ ...selectedQuantities, [productId]: quantity });
	//	console.log('quntity',selectedQuantities)
	  };

	  useEffect(() => {
		setSelectedQuantities({});
	  }, [selectedBusiness]);
		
	  const addProductFunction = async () => {
		try {
			setLoading(true)
		  if (!selectedBusiness) {
			setShowToast(true);
			setToastMessage('Please select a business before adding the order.');
			return;
		  }
	  		  if (!selectedQuantities || Object.keys(selectedQuantities).length === 0) {
			setShowToast(true);
			setToastMessage('Please select at least one product before adding the order.');
			return;
		  }
	  	// 	  setShowToast(true);
		//   setToastMessage('Order added successfully.');
	  
		  const orderProducts = Object.keys(selectedQuantities).map(productId => ({
			id: productId,
			count_pickup: selectedQuantities[productId],
			notes_pickup: "this is by me2"
		  }));
	  
		  const orderData = {
			business_id: selectedBusiness.id,
			order_products: orderProducts,
			
		  };
		//   setLoading(true)
		  const response = await fetch('https://api.citylinencare.com/orders', {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json',
			  authorization: `Bearer ${getAccessToken().token}`
			},
			body: JSON.stringify(orderData),
		  });
		 
		  const responseBody = await response.json();
		 
		  console.log('Response Body:', responseBody);
	  console.log(response)
	
		  if (responseBody.data.id ) {
			
			const orderId = responseBody.data.id;
			setToastMessage('Order added successfully.');

			setShowToast(true);
handleUpdateItem()
			console.log(showToast);
			console.log("this is ordercreare",ordercreate);
							// history.push('/app/orders/act');

			// // Delay the redirection and list reload
			// (() => {
			// 	history.push('/app/orders/act');
			// 	if (!localStorage.getItem('reload')) {
			// 		localStorage['reload'] = true;
			// 	} else {
			// 		window.location.reload();
			// 		localStorage.removeItem('reload');
			// 	}
			// })(); // Adjust the delay time as needed
		
			setSelectedBusiness(null);
			setSelectedQuantities({});
		} 
		 
		 
		  else {
             console.log("hello")
			setToastMessage('Order not added successfully.');
			setShowToast(true);

			// setOrderCreate(false)
			throw new Error('Failed to add order');
		  }
     

		} catch (error) {
			console.log("this is one")
		  console.error('Error:', error.message);
		  setToastMessage('Failed to add order. Please try again.');

		  setShowToast(true);
		}
	  };
	//   useEffect(() => {
	// 	if (ordercreate) {
	// 		console.log("jell");
	// 		history.push('/app/orders/act');
	// 		if (!localStorage.getItem('reload')) {
	// 			localStorage['reload'] = true;
	// 		} else {
	// 			window.location.reload();
	// 			localStorage.removeItem('reload');
	// 		}
			
	// 	}
	// }, [ordercreate]);
	const handleFileChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (event.target.files.length > 0) {
			const file = event.target.files.item(0);
			if (file.type.startsWith('image')) {
				setIsImageEmpty(false);
				let temp2 = true;
				setIsImageValidate(temp2);
				const myPictureUrl = URL.createObjectURL(file);
				let temp3 = myPictureUrl;
				setPictureUrl(temp3);
				setLoading(true);
				const myPlatformPath = await savePicture(file);
				setLoading(false);
				if (myPlatformPath) {
					let temp4 = myPlatformPath;
					setPlatformPath(temp4);
				}
			} else {
				let temp2 = false;
				setIsImageValidate(temp2);
			}
		}
	};

	const handlePictureClick = async () => {
		if (isPlatform('capacitor')) {
			try {
				const photo = await Camera.getPhoto({
					resultType: CameraResultType.Uri,
				});
				let temp = photo.webPath;
				setPictureUrl(temp);
			} catch (error) {
				console.log(error);
			}
		} else {
			fileInputRef.current.click();
		}
	};

	//validate functions
	const productNameValidation = () => {
		let result = true;
		if (productName.length < 3) {
			setIsProductNameEmpty(true);
			result = false;
		} else {
			setIsProductNameEmpty(false);
		}
		return result;
	};

	const imageValidation = () => {
		let result = true;
		if (!platformPath) {
			setIsImageEmpty(true);
			// console.log(platformPath);
			result = false;
		} else {
			setIsImageEmpty(false);
		}
		return result;
	};
	const priceValidation = () => {
		let result = true;
		if (!servicePrice) {
			setIsServicePriceEmpty(true);
			result = false;
		} else {
			setIsServicePriceEmpty(false);
			if (!/^\d+(\.\d{1,2})?$/.test(servicePrice)) {
				setIsServicePriceValidate(false);
				result = false;
			} else {
				setIsServicePriceValidate(true);
			}
		}
		return result;
	};

	const weightValidation = () => {
		// Initialize result variable
		let result = true;
		// Check if weight is empty
		if (!weight) {
			setIsWeightEmpty(true);
			result = false;
		} else {
			setIsWeightEmpty(false);
			// Check if weight matches the required format
			if (!/^\d+(\.\d{1,2})?$/.test(weight)) {
				setIsWeightValidate(false);
				result = false;
			} else {
				setIsWeightValidate(true);
			}
		}
		return result;
	};

	//  Add validation for dimension
	const dimensionValidation = () => {
		let result = true;
		if (!dimension.length) {
			setIsDimensionEmpty(true);
			result = false;
		} else {
			setIsDimensionEmpty(false);
		}
		return result;
	};

	//Add validation for help_text
	const helpTextValidation = () => {
		let result = true;
		if (!helpText.length) {
			setIsHelpTextEmpty(true);
			result = false;
		} else {
			setIsHelpTextEmpty(false);
		}
		return result;
	};

	// Add validation for internal_notes
	const internalNotesValidation = () => {
		let result = true;
		if (!internalNotes.length) {
			setIsInternalNotesEmpty(true);
			result = false;
		} else {
			setIsInternalNotesEmpty(false);
		}
		return result;
	};

	// const addProductFunction = async () => {
	// 	// console.log('name ', productName);
	// 	// console.log('status ', productStatus);
	// 	// console.log('platformPath ', platformPath);
	// 	// sessionStorage.setItem('newProductAdded', String(true));
	// 	const accessToken = await getAccessToken();
	// 	const res1 = productNameValidation();
	// 	const res2 = imageValidation();
	// 	const res3 = priceValidation();
	// 	const res4 = weightValidation();
	// 	const res5 = dimensionValidation();
	// 	const res6 = helpTextValidation();
	// 	const res7 = internalNotesValidation();

	// 	if (res1 && res2 && res3 && res4 && res5 && res6 && res7 && accessToken) {
	// 		console.log('everything is fine');
	// 		const newProduct = {
	// 			id: 0,
	// 			name: productName,
	// 			image: platformPath,
	// 			status: productStatus,
	// 			weight_in_gms: weight,
	// 			laundry_service_price: servicePrice,
	// 			dimension: dimension,
	// 			help_text: helpText,
	// 			internal_notes: internalNotes,
	// 		};
	// 		// console.log(newProduct);
	// 		dispatch(createProduct(newProduct, accessToken.token));
	// 	}
	// };

	// useEffect(() => {
	// 	setLoading(false);
	// 	if (createProductMessage?.id) {
	// 		setShowToast(true);
	// 		setToastMessage('Product Added Successfully');
	// 		setProductName('');
	// 		setPlatformPath('');
	// 		setProductStatus('active');
	// 		setPictureUrl('/assets/placeholder.svg');
	// 		setWeight('');
	// 		setServicePrice('');
	// 		setDimension('');
	// 		setHelpText('');
	// 		setInternalNotes('');

	// 		// console.log('Product Added Successfully');
	// 		// console.log(createProductMessage);
	// 	}
	// 	if (createProductError) {
	// 		//if any error happened
	// 		setShowToast(true);
	// 		setToastMessage('Something went wrong');
	// 	}
	// }, [createProductMessage, createProductError]);
	// console.log(createProductLoading, ' createProductLoading');
	// console.log(loading);
	// console.log(isFormSubmitted);
	




	// Function to handle dismiss of the toast
const handleToastDismiss = () => {
	setToastMessage('');
	setShowToast(false);
    // history.push('/app/orders/act'); // Redirect to the same place
};
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonIcon
							size="large"
							onClick={() => history.push('/app/orders/act')}
							icon={arrowBackIcon}
						/>
					</IonButtons>
					<IonTitle>Add Order</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent className="ion-padding hideOnMobile">
				<div
					style={{
						maxWidth: '100%',
						margin: '0 auto',
						border: '1px solid #eee',
					}}
					className="ion-padding"
				>
					{/* Display Business Names */}
<IonItem className="ion-margin-bottom" onClick={() => setShowModal(true)}  style={{cursor:"pointer"}}>
  <IonLabel>Select Business Name</IonLabel>

  {selectedBusiness ? <IonText slot="end">{selectedBusiness.name}</IonText> : ""}


  <IonIcon slot="end" icon={caretDownIcon} />
</IonItem>

{/* this is the modal --saubhagya working fine  */}
<IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
  <IonHeader>
    <IonItem lines="none">
      <IonTitle>Select Business Name</IonTitle>
      <IonButton fill="clear" size="large" slot="end" onClick={() => setShowModal(false)}>
        <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
      </IonButton>
    </IonItem>
  </IonHeader>
  <IonContent className="ion-padding">
    <IonRadioGroup
      value={selectedBusiness ? selectedBusiness.id : undefined}
      onIonChange={(e) => {
        handleBusinessChange(e.detail.value);
        setShowModal(false);
      }}
    >
      {businessData.map((business) => (
        <IonItem
          key={business.id}
          onClick={() => {
            handleBusinessClick(business);
            setShowModal(false);
          }}
          lines="none"
          className="ion-padding-start"
        >
          <IonText>
            <h5>{business.name}</h5>
          </IonText>
          <IonRadio slot="start" value={business.id} />
        
        </IonItem>
      ))}
    </IonRadioGroup>
  </IonContent>
</IonModal>

{/* Display Products */}

<IonCard>


{ showhead &&
(<div className="desktop-view">
<IonGrid >
	<IonRow>
	<IonCol size='4' >
	<IonItem  className='ion-no-padding'>

<IonLabel  className='ion-no-padding ion-text-center '><h2>Image</h2></IonLabel>
</IonItem>
		</IonCol>
		<IonCol size='4' >
	<IonItem  className='ion-no-padding'>

<IonLabel  className='ion-no-padding ion-text-center'><h2>Name
</h2></IonLabel>
</IonItem>
		</IonCol>
		<IonCol size='4'>
	<IonItem  className='ion-no-padding'>

<IonLabel  className='ion-no-padding ion-text-center'><h2>Count
</h2></IonLabel>
</IonItem>
		</IonCol>
	</IonRow>

</IonGrid></div>)
}

	
  {products.map((product) => (
    <IonItem key={product.id}>
		
      <IonGrid>
        <IonRow>
          <IonCol className='ion-no-padding ion-text-center'
		   style={{border:"0px solid blue",width:"90px",height:"90px",display:"flex",justifyContent:"center"}} 
		   >
			
			<IonImg src={product.image_display_url} alt="Product Image" style={{ height: "80px", width: "80px",marginRight:"20px"}}  className='hideOnMobile ion-no-padding '/>
   
          </IonCol>

          <IonCol  sizeMd='4' className='hideOnMobile ion-text-center'>
		  <IonItem lines='none' style={{ paddingTop: "18px",marginRight:"0px",border:"0px solid red" }} className='hideOnMobile ion-text-center'>

            <IonLabel className='hideOnMobile ' >{product.name}</IonLabel>
			</IonItem>

          </IonCol>
          <IonCol className='hideOnMobile ion-text-center' >
			<IonItem style={{  }} className='ion-text-center' lines='inset'>
              {/* <IonLabel style={{ marginRight: '10px', fontSize: '16px' }} >Count :</IonLabel> */}
              <IonInput
                // className='ion-text-center'
				inputmode="numeric"
				pattern="[0-9]*"

onKeyPress={(e) => {
						  // Get the pressed key
						  const key = e.key;
				  
						  // Check if the pressed key is not a number
						  if (!/^\d$/.test(key)) {
							  // Prevent the default action (typing the character)
							  e.preventDefault();
						  }
					  }}
                placeholder="   Enter count"
                onIonChange={(e) => handleQuantityChange(e, product.id)}
                style={{}}
              ></IonInput>
            </IonItem>
          </IonCol>

		 



        </IonRow>
      </IonGrid>
    </IonItem>
  ))}
</IonCard>
{/* Comment xx */}
	
					<IonGrid>
						<IonRow>
							{/* comment x3 */}
						</IonRow>
					</IonGrid>
					{/* comment x4 */}
					
					<div className="ion-text-center">

					

						<IonButton shape="round" onClick={addProductFunction}>
							Add Order
						</IonButton>
						

						
					</div>
					<IonLoading
        isOpen={!!createProductLoading || loading}
        message={'Please wait...'}
      />
					{/* <IonLoading isOpen={!!createProductLoading} message="Please Wait" /> */}
				</div>

				<IonToast
					isOpen={showToast}
					message={toastMessage}
					onDidDismiss={handleToastDismiss} // Call handleToastDismiss when toast is dismissed

					duration={2000}
					position="top"
				></IonToast>
			</IonContent>






<IonContent className="ion-no-padding showOnMobile">
				<div
					style={{
						maxWidth: '100%',
						margin: '0 auto',
						border: '1px solid #eee',
					}}
					className="ion-no-padding"
				>
					{/* Display Business Names */}
<IonItem className="ion-margin-bottom" onClick={() => setShowModal(true)}  style={{cursor:"pointer"}}>
  <IonLabel>Select Business Name</IonLabel>

  {selectedBusiness ? <IonText slot="end">{selectedBusiness.name}</IonText> : ""}


  <IonIcon slot="end" icon={caretDownIcon} />
</IonItem>

{/* this is the modal --saubhagya working fine  */}
<IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
  <IonHeader>
    <IonItem lines="none">
      <IonTitle>Select Business Name</IonTitle>
      <IonButton fill="clear" size="large" slot="end" onClick={() => setShowModal(false)}>
        <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
      </IonButton>
    </IonItem>
  </IonHeader>
  <IonContent className="ion-padding">
    <IonRadioGroup
      value={selectedBusiness ? selectedBusiness.id : undefined}
      onIonChange={(e) => {
        handleBusinessChange(e.detail.value);
        setShowModal(false);
      }}
    >
      {businessData.map((business) => (
        <IonItem
          key={business.id}
          onClick={() => {
            handleBusinessClick(business);
            setShowModal(false);
          }}
          lines="none"
          className="ion-padding-start"
        >
          <IonText>
            <h5>{business.name}</h5>
          </IonText>
          <IonRadio slot="start" value={business.id} />
        
        </IonItem>
      ))}
    </IonRadioGroup>
  </IonContent>
</IonModal>


<IonCard>

	{showhead && (<div className="showOnMobile">
		<IonGrid>
			<IonRow>
				<IonCol className='ion-text-center'>
                        Name
				</IonCol>
				<IonCol className='ion-text-center'>
                        Image
				</IonCol>
				<IonCol className='ion-text-center'>
                        Count
				</IonCol>
			</IonRow>
		</IonGrid>
	</div>)}

  {products.map((product) => (
    <IonItem key={product.id}>
      <IonGrid>
        <IonRow>
          <IonCol className='ion-no-padding' style={{border:"0px solid blue",width:"100px",height:"90px"}} >
		
            {/* <div  className="showOnMobile" style={{display:"inline-block",border:"1px solid red", height: "80px", width: "90px"}}  > */}
			<IonImg   src={product.image_display_url} alt="Product Image" style={{ height: "80px", width: "80px",border:"0px solid green"}} className='showOnMobile '/>
			{/* </div> */}
   
          </IonCol>

		  <IonCol    className='showOnMobile'>
			
		  <IonItem lines='none' style={{ paddingTop: "9px",width:'120px',display:'flex',flexWrap:'wrap',paddingLeft:'4px' }} className='ion-no-padding'>

            <IonLabel className='ion-no-padding' style={{textWrap:'wrap',border:"0px solid red",display:'flex',flexWrap:'wrap'}}>{product.name}</IonLabel>
			
			</IonItem>
        
          </IonCol>

		  <IonCol className='showOnMobile'  >
			<IonItem lines='inset' style={{ padding: "2px",border:'0px solid pink',marginLeft:'4px' }} className='ion-no-padding'>
             
              {/* <IonInput 
			  
                type="number"
                value={selectedQuantities[product.id] || ""}
                // placeholder=" count"
                onIonChange={(e) => handleQuantityChange(e, product.id)}
                style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '5px', width: '60px' }}
              ></IonInput> */}

<IonInput
                                        inputmode="numeric"
                                        pattern="[0-9]*"
                                    // onIonChange={(e) => handleQuantityChange(e, product.id)}
                                    // placeholder={`Enter the count (${product.count_pickup})`}
                                    onKeyPress={(e) => {
                                        // Get the pressed key
                                        const key = e.key;
                                
                                        // Check if the pressed key is not a number
                                        if (!/^\d$/.test(key)) {
                                            // Prevent the default action (typing the character)
                                            e.preventDefault();
                                        }
                                    }}
                                    onIonChange={(e) => {
                                        const inputValue = e.detail.value || '';
                                        const sanitizedValue = inputValue.replace(/\D/g, ''); // Remove non-digit characters
                                
                                        const customEvent = new CustomEvent('ionChange', {
                                            detail: {
                                                value: sanitizedValue
                                            }
                                        });
                                
                                        handleQuantityChange(customEvent, product.id);}}
                                ></IonInput>
            </IonItem>
          </IonCol>

        </IonRow>
      </IonGrid>
    </IonItem>
  ))}
</IonCard>

	
					<IonGrid>
						<IonRow>
							
						</IonRow>
					</IonGrid>
					
					<div className="ion-text-center">
					<Link to={{ pathname: `/app/orders/act`}}>
						<IonButton shape="round" onClick={addProductFunction}>
							Add Order
						</IonButton></Link>
					</div>
					<IonLoading
        isOpen={!!createProductLoading || loading}
        message={'Please wait...'}
      />
					{/* <IonLoading isOpen={!!createProductLoading} message="Please Wait" /> */}
				</div>

				<IonToast
					isOpen={showToast}
					message={toastMessage}
					onDidDismiss={handleToastDismiss} // Call handleToastDismiss when toast is dismissed

					duration={4000}
					position="top"
				></IonToast>
			</IonContent>
		</IonPage>
	);
};

export default AddOrder;
