export const GET_PRODUCT_BY_ID_REQUEST = 'GET_PRODUCT_BY_ID_REQUEST'
export const GET_PRODUCT_BY_ID_SUCCESS = 'GET_PRODUCT_BY_ID_SUCCESS'
export const GET_PRODUCT_BY_ID_FAIL = 'GET_PRODUCT_BY_ID_FAIL'

export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST'
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS'
export const CREATE_PRODUCT_FAIL = 'CREATE_PRODUCT_FAIL'

export const EDIT_PRODUCT_REQUEST = 'EDIT_PRODUCT_REQUEST'
export const EDIT_PRODUCT_SUCCESS = 'EDIT_PRODUCT_SUCCESS'
export const EDIT_PRODUCT_FAIL = 'EDIT_PRODUCT_FAIL'

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL'

export const SEARCH_PRODUCT_QUERY_REQUEST = 'SEARCH_PRODUCT_QUERY_REQUEST'
export const SEARCH_PRODUCT_QUERY_SUCCESS = 'SEARCH_PRODUCT_QUERY_SUCCESS'
export const SEARCH_PRODUCT_QUERY_FAIL = 'SEARCH_PRODUCT_QUERY_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'