import React from 'react'
import {
    fileTrayFull as fileTrayFullIcon,
    notificationsOutline as notificationIcon
} from 'ionicons/icons';
import { IonHeader, 
    IonButtons, 
    IonMenuButton, 
    IonTitle, 
    IonToolbar, 
    IonGrid, 
    IonRow, 
    IonCol, 
    IonButton
} from '@ionic/react';

interface Props{
    name: string
}
const Title = (props : Props) => {
    return (
        <IonHeader class="">
            <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton></IonMenuButton>
                </IonButtons>
                <IonTitle className="welcomestorename">{props.name}</IonTitle>
                {/* <IonButtons slot="end">
                <IonButton>
                    <IonIcon slot="icon-only" icon={fileTrayFullIcon}></IonIcon>
                    </IonButton>
                    <IonButton>
                    <IonIcon slot="icon-only" icon={notificationIcon}></IonIcon>
                    </IonButton>
                </IonButtons> */}
            </IonToolbar>
        </IonHeader>
    )
}

export default Title