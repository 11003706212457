import { Route, useLocation } from 'react-router-dom';
import {
	IonApp,
	IonContent,
	IonLoading,
	IonMenu,
	IonPage,
	IonRouterOutlet,
	IonSplitPane,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessToken } from './services/auth/userLocalStorage';
import {
	authenticateUserWhenLogin,
	getCurrentUser,
} from './actions/authActions';
import { setupIonicReact } from '@ionic/react';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Home from './auth/Home/Home';
import GetBusinessDetails from './auth/GetBusinessDetails';
import DashboardMenu from './components/dashboard/DashboardMenu';
import MainTabs from './MainTabs';
import SignUpWithEmailOTP from './auth/SignUpWithEmailOTP/SignUpWithEmailOTP';
import RedirectToLogin from './RedirectToLogin';
import SSO from './pages/SSO';
import { useHistory } from 'react-router-dom';
import ProtectedRoute from './route/ProtectedRoute';
import Dashboard from './dashboard/Dashboard';
import DashboardHome from './dashboard/Home/DashboardHome';
import HelpendSupport from './components/order/HelpendSupport';
import OrderDetailsPage from './components/order/OrderDetailsPage';
import OrderState from './components/order/orderState';
import HelpAndSupport from './dashboard/HelpAndSupport/HelpAndSupport';
import Tab2 from './components/order/tab2';
import AddOrder from './components/order/AddOrder';

setupIonicReact({
	mode: 'md',
});
/**
 * Main component for the application.
 * It handles authentication, loads the current user, and renders the appropriate routes.
 */
const App: React.FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const isHome = location.pathname === '/';
	const isEmailOtpLogin = location.pathname === '/email-otp-login';

	const { currentUser, currentUserLoading } = useSelector(
		(state: any) => state.currentUser
	);
	let showMenu = false;

	// Check if token exists and the current page is not the home page or email OTP login page
	const token = getAccessToken();
	if (token?.token && !isHome && !isEmailOtpLogin) {
		showMenu = true;
	}

	// useEffect(() => {
	// 	if (isUserAuthenticate && currentUserLoading) {
	// 		window.location.reload();
	// 	}
	// }, [currentUserLoading, isUserAuthenticate]);
	// console.log(currentUser);
	// console.log(currentUserLoading);
	// console.log('isUserAuthenticate ', isUserAuthenticate);

	/**
	 * Load the current user on component mount.
	 */
	useEffect(() => {
		loadCurrentUser();
	}, []);

	/**
	 * Load the current user by getting the access token and dispatching the `getCurrentUser` action.
	 */
	const loadCurrentUser = async () => {
		const token = await getAccessToken();
		if (token?.token) await dispatch(getCurrentUser(token));
	};
	/**
	 * Check if the current user is loaded and dispatch the `authenticateUserWhenLogin` action.
	 */
	useEffect(() => {
		if (currentUser && currentUser.data) {
			dispatch(authenticateUserWhenLogin());
		}
	}, [currentUser]);

	return (
		<OrderState>
			<IonApp>
			<IonReactRouter>
				<IonSplitPane contentId="main">
					{showMenu && <DashboardMenu />}
					<IonRouterOutlet id="main">
						<Route path="/" component={Home} exact />
						<Route path="/sso" component={SSO} exact />
						<Route path="/email-otp-login" component={SignUpWithEmailOTP} />
						<Route path="/getbusinessdetails" component={GetBusinessDetails} />

						<Route
							path="/logout"
							render={() => {
								return <RedirectToLogin />;
							}}
						/>
						<Route
							path="/app"
							render={() => {
								return <MainTabs />;
							}}
						/>

                       {/* <Route
							path="/app/helpsupport"
							render={() => {
								return <HelpendSupport />;
							}}
						/> */}
<Route path="/orders/:id" component={OrderDetailsPage} exact={true} />
<Route  path="/app/orders/all" component={Tab2}/>
            
<Route path="/support" component={HelpendSupport}  />
<Route path="/orders/addorders" component={AddOrder}  />

					</IonRouterOutlet>
				</IonSplitPane>
			</IonReactRouter>
			<IonLoading
				isOpen={currentUserLoading}
				message={'Please Wait...'}
			></IonLoading>
		</IonApp>
		</OrderState>

		
		
	);
};

export default App;
