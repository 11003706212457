import React, { useState,useContext} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IntelikartDashboardLogo from '../../images/IntelikartDashboardLogo.png';
import { RouteComponentProps, withRouter, useLocation } from 'react-router';
import {
	IonMenu,IonHeader,IonIcon,IonImg,
	IonToolbar,
	IonTitle,
	IonContent,
	IonList,
	IonItem,
	IonLabel,
	IonMenuToggle,
	IonListHeader,
	IonAlert,
} from '@ionic/react';
import {
	homeOutline,
	logOutOutline,
	pricetagOutline,
	settingsOutline,
	storefrontOutline,
	chatbubbleEllipsesOutline,
	rocketOutline,
	thumbsUpOutline,
	megaphoneOutline,
	albumsOutline,
	readerOutline,
	fileTrayOutline,
	people,
	card,
	cardOutline,
	contractSharp,
	call,
	callOutline
} from 'ionicons/icons';
import './Menu.css';

// import { getAccessToken } from '../../services/auth/userLocalStorage';
import LogoImage from '../auth/LogoImage';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import OrderState from '../order/orderState';
import orderContext from '../order/orderContext';

interface Pages {
	title: string;
	path: string;
	icon: string;
	routerDirection?: string;
}
const DashboardMenu = () => {
	const { isTabBarHidden, handleTabButtonClick } = useContext(orderContext);

	// const { currentUser } = useSelector((state: any) => state.currentUser);
	const [showAlert, setShowAlert] = useState(false);
	const location = useLocation();
	const history = useHistory();
	const token = getAccessToken();
	// console.log('inDash');
	const routes = {
		
		// added
		appPages: [
			{ title: 'Home', path: '/app/home', icon: homeOutline },
	
			{ title:'Orders' ,path:'/app/orders/act',icon: fileTrayOutline
			},
			{ title:'Help & Support' ,path:'/support',icon: callOutline
			}
			
		],
	};

	function renderListItems(list: Pages[]) {
		return list
			.filter(route => !!route.path)
			.map(p => {
				const isActive = (p.title === 'Orders' && (location.pathname.includes('/app/orders') || location.pathname.includes('/orders'))) ||
				(p.title === 'Help & Support' && location.pathname.includes('/support')) ||
				(p.title === 'Home' && (location.pathname === '/' || location.pathname === '/app/home'));
				return (
					<OrderState>
					<IonMenuToggle key={p.title} auto-hide="false">
						<IonItem
							detail={false}
							routerLink={p.path}
							// routerDirection="none"
							className={isActive ? 'selected' : undefined}
							// color={!isAc/tive ? 'tertiary' : 'primary'}
							// className={
							// 	(p.title === 'Orders' && (location.pathname.includes('/app/orders') || location.pathname.includes('/orders'))) ||
							// 	(p.title === 'Help & Support' && location.pathname.includes('/support')) ? 'selected' :
							// 	(p.title === 'Home' && (location.pathname === '/' || location.pathname === '/app/home')) ? 'selected' :
							// 	undefined
							// }
							

							onClick={() => {
							    if(`${p.title}` === 'Orders'){
									handleTabButtonClick('orders')
								}
								else
								{handleTabButtonClick('products')}}}
						>
							<IonIcon slot="start" icon={p.icon} color={!isActive ? 'tertiary' : 'primary'}/>
							<IonLabel>{p.title}</IonLabel>
						</IonItem>
					</IonMenuToggle></OrderState>
				);
			});
	}
	return (
		<OrderState>
		<IonMenu type="overlay" contentId="main" class="sidemenu">
			<IonHeader>
				<IonToolbar>
					<IonTitle>
						<IonItem lines="none" detail={false}>
							<LogoImage width="100" />
						</IonItem>
					</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonList lines="none">{renderListItems(routes.appPages)}</IonList>
				<IonList lines="none">
					<IonListHeader>Others</IonListHeader>
					<IonMenuToggle key="LOGOUT" auto-hide="false">
						<IonItem
							detail={false}
							onClick={e => setShowAlert(true)}
							style={{ cursor: 'pointer' }}
							routerDirection="none"
							className={
								location.pathname.startsWith('/logout') ? 'selected' : undefined
							}
						>
							<IonIcon slot="start" icon={logOutOutline} />
							<IonLabel>Logout</IonLabel>
						</IonItem>
					</IonMenuToggle>
				</IonList>
			</IonContent>
			<IonAlert
				isOpen={showAlert === true}
				onDidDismiss={() => setShowAlert(false)}
				header={'Confirm Log Out'}
				message={`Are you sure you want to log out?`}
				buttons={[
					{
						text: 'NO',
						role: 'cancel',
					},
					{
						text: 'YES',
						handler: () => {
							history.push('/logout');
						},
					},
				]}
			/>
		</IonMenu></OrderState>
	);
};

export default DashboardMenu;
