import {
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonImg,
	IonInput,
	IonLoading,
	IonPage,
	IonRow,
	IonText,
	IonThumbnail,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import {
	callSharp,
	checkboxSharp,
	createSharp,
	globeSharp,
	locationSharp,
	logoGooglePlaystore,
	logoWhatsapp,
	arrowBack,
} from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { API_ROOT } from '../../constants/global';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import Header from '../dashboard/Header';

const MyStoreDetails = (e: any) => {
	const user_id = e.match.params.id;
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);

	const [logo, setLogo] = useState<any>();
	const [name, setName] = useState<any>();
	const [address, setAddress] = useState<any>();
	const [latitude, setLatitude] = useState<any>();
	const [longitude, setLongitude] = useState<any>();
	const [phone, setPhone] = useState<any>();
	const [whatsapp, setWhatsapp] = useState<any>();
	const [website, setWebsite] = useState<any>();
	const [app, setApp] = useState<any>();
	const [productsCount, setProductCount] = useState<any>();
	const [ordersCount, setOrderCount] = useState<any>();
	const [customersCount, setCustomersCount] = useState<any>();
	const [subscriptions, setSubscription] = useState([]);

	useEffect(() => {
		const token = getAccessToken();

		setIsLoading(true);
		const fetchData = async () => {
			const response = await fetch(`${API_ROOT}partners/stores/${user_id}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${token.token}`,
				},
			});
			const resdata = await response.json();
			if (resdata !== undefined) {
				setName(resdata?.data.name);
				setLogo(resdata?.data.logo);
				setAddress(resdata?.data.address);
				setLongitude(resdata?.data.longitude);
				setLatitude(resdata?.data.latitude);
				setPhone(resdata?.data.phone);
				setWhatsapp(resdata?.data.whatsapp);
				setWebsite(resdata?.data.website_link);
				setApp(resdata?.data.app_link);
				setProductCount(resdata?.data.products_count);
				setOrderCount(resdata?.data.orders_count);
				setCustomersCount(resdata?.data.customers_count);
				setSubscription(resdata?.data.subscriptions);
			}
		};
		setIsLoading(false);

		fetchData();
	}, [user_id]);

	return (
		<IonPage>
			<IonHeader>
				<IonButton fill="clear" color="dark" onClick={() => history.goBack()}>
					<IonIcon icon={arrowBack} slot="icon-only" />{' '}
				</IonButton>
			</IonHeader>
			<IonContent>
				<IonGrid
					className="ion-no-padding ion-no-margin"
					style={{ color: '#353535' }}
				>
					<IonRow>
						<IonCol>
							<div>
								<IonGrid>
									<IonRow className="ion-align-items-center ion-text-center">
										<IonCol size="2" />
										<IonCol size="2">
											<img src={logo} width={100} height={100} alt="" />
										</IonCol>
										<IonCol size="5">
											<IonText
												className="ion-text-uppercase"
												style={{
													fontWeight: 'bold',
													letterSpacing: '2px',
													fontSize: '35px',
												}}
											>
												{name}
											</IonText>
										</IonCol>
										<IonCol size="2" className="ion-text-left">
											<IonText
												className="ion-text-uppercase"
												style={{
													fontWeight: 'light',
													fontSize: '20px',
												}}
											>
												{subscriptions[0]?.package}
											</IonText>
										</IonCol>
									</IonRow>
								</IonGrid>
							</div>
							<div>
								<IonCard>
									<IonCardContent>
										<IonGrid className="ion-no-padding ion-no-margin">
											<IonRow>
												<IonCol
													className="ion-padding-bottom ion-no-margin"
													size="4"
												>
													<IonIcon
														icon={locationSharp}
														style={{ zoom: '1.5' }}
													/>
													<IonText
														className="dashboard-fnt-sz ion-padding-left ion-margin"
														style={{
															fontSize: '25px',
														}}
													>
														Address
													</IonText>
												</IonCol>
											</IonRow>
											<IonRow>
												<IonCol className="ion-no-padding ion-no-margin">
													<IonText
														className="websitelink"
														style={{
															fontSize: '18px',
														}}
													>
														{address}
													</IonText>
												</IonCol>
												<IonCol className="ion-text-center">
													<IonText style={{ fontSize: '15px' }}>
														<Link to="">View on map</Link>
													</IonText>
												</IonCol>
											</IonRow>
										</IonGrid>
									</IonCardContent>
								</IonCard>
							</div>
							<div>
								<IonCard>
									<IonCardContent>
										<IonGrid className="ion-no-padding ion-no-margin">
											<IonRow>
												<IonCol
													className="ion-padding-bottom ion-no-margin"
													size="4"
												>
													<IonIcon icon={callSharp} style={{ zoom: '1.5' }} />
													<IonText
														className="dashboard-fnt-sz ion-padding-left ion-margin"
														style={{
															fontSize: '20px',
														}}
													>
														Phone -
													</IonText>
													<IonText
														style={{
															fontSize: '18px',
														}}
													>
														{phone}
													</IonText>
												</IonCol>
											</IonRow>
											<IonRow>
												<IonCol
													className="ion-no-padding ion-no-margin"
													size="4"
												>
													<IonIcon
														icon={logoWhatsapp}
														style={{ zoom: '1.5' }}
													/>
													<IonText
														className="dashboard-fnt-sz ion-padding-left ion-margin"
														style={{
															fontSize: '20px',
														}}
													>
														WhatsApp -
													</IonText>
													<IonText
														style={{
															fontSize: '18px',
														}}
													>
														{whatsapp}
													</IonText>
												</IonCol>
											</IonRow>
										</IonGrid>
									</IonCardContent>
								</IonCard>
							</div>
							<div>
								<IonCard>
									<IonCardContent>
										<IonGrid className="ion-no-padding ion-no-margin">
											<IonRow>
												<IonCol
													className="ion-padding-bottom ion-no-margin"
													size="4"
												>
													<IonIcon icon={globeSharp} style={{ zoom: '1.5' }} />
													<IonText
														className="dashboard-fnt-sz ion-padding-left ion-margin"
														style={{
															fontSize: '20px',
														}}
													>
														Website Link -
													</IonText>
													<IonText
														style={{
															fontSize: '18px',
														}}
													>
														{website}
													</IonText>
												</IonCol>
											</IonRow>
											<IonRow>
												<IonCol
													className="ion-no-padding ion-no-margin"
													size="4"
												>
													<IonIcon
														icon={logoGooglePlaystore}
														style={{ zoom: '1.5' }}
													/>
													<IonText
														className="dashboard-fnt-sz ion-padding-left ion-margin"
														style={{
															fontSize: '20px',
														}}
													>
														App Link -
													</IonText>
													<IonText
														style={{
															fontSize: '18px',
														}}
													>
														{app}
													</IonText>
												</IonCol>
											</IonRow>
										</IonGrid>
									</IonCardContent>
								</IonCard>
							</div>
							<div>
								<IonGrid>
									<IonRow>
										<IonCol>
											<IonCard className="ion-no-margin ion-no-padding">
												<IonCardHeader>
													<IonText className="dashboard-fnt-sz">
														Products Count
													</IonText>
													<IonCardTitle className="fnt-sz28">
														{productsCount}
													</IonCardTitle>
												</IonCardHeader>
											</IonCard>
										</IonCol>
										<IonCol>
											<IonCard className="ion-no-margin ion-no-padding">
												<IonCardHeader>
													<IonText className="dashboard-fnt-sz">
														Orders Count
													</IonText>
													<IonCardTitle className="fnt-sz28">
														{ordersCount}
													</IonCardTitle>
												</IonCardHeader>
											</IonCard>
										</IonCol>
										<IonCol>
											<IonCard className="ion-no-margin ion-no-padding">
												<IonCardHeader>
													<IonText className="dashboard-fnt-sz">
														Customers Count
													</IonText>
													<IonCardTitle className="fnt-sz28">
														{customersCount}
													</IonCardTitle>
												</IonCardHeader>
											</IonCard>
										</IonCol>
									</IonRow>
								</IonGrid>
							</div>
							<div>
								<IonCard>
									<IonCardContent>
										<IonGrid className="ion-no-padding ion-no-margin">
											<IonRow>
												<IonCol
													className="ion-padding-bottom ion-no-margin"
													size="4"
												>
													<IonIcon
														icon={checkboxSharp}
														style={{ zoom: '1.5' }}
													/>
													<IonText
														className="dashboard-fnt-sz ion-padding-left ion-margin"
														style={{
															fontSize: '20px',
														}}
													>
														Subscriptions
													</IonText>
												</IonCol>
											</IonRow>
											{subscriptions?.map(row => {
												return (
													<IonRow key={row.start_date}>
														<IonCol className="ion-no-padding ion-no-margin">
															<IonText
																className="websitelink"
																style={{
																	fontSize: '18px',
																}}
															>
																{new Date(row.start_date)
																	.toDateString()
																	.split(' ')
																	.slice(1)
																	.join(' ')}
															</IonText>
														</IonCol>
														<IonCol className="ion-no-padding ion-no-margin">
															<IonText
																className="websitelink"
																style={{
																	fontSize: '18px',
																}}
															>
																{new Date(row.end_date)
																	.toDateString()
																	.split(' ')
																	.slice(1)
																	.join(' ')}
															</IonText>
														</IonCol>
														<IonCol className="ion-no-padding ion-no-margin">
															<IonText
																className="websitelink"
																style={{
																	fontSize: '18px',
																}}
															>
																{row.package}
															</IonText>
														</IonCol>
													</IonRow>
												);
											})}
										</IonGrid>
									</IonCardContent>
								</IonCard>
							</div>
							<div>
								<IonCard>
									<IonCardContent>
										<IonGrid className="ion-no-padding ion-no-margin">
											<IonRow>
												<IonCol
													className="ion-padding-bottom ion-no-margin"
													size="4"
												>
													<IonIcon icon={createSharp} style={{ zoom: '1.5' }} />
													<IonText
														className="dashboard-fnt-sz ion-padding-left ion-margin"
														style={{
															fontSize: '20px',
														}}
													>
														Communication
													</IonText>
												</IonCol>
											</IonRow>
											<IonRow>
												<IonCol>
													<form action="">
														<IonRow>
															<IonCol size="3">
																<IonInput placeholder="Add a new comm" />
															</IonCol>
															<IonCol size="2">
																<IonButton color="dark" size="small">
																	Save
																</IonButton>
															</IonCol>
														</IonRow>
													</form>
												</IonCol>
											</IonRow>
										</IonGrid>
									</IonCardContent>
								</IonCard>
							</div>
						</IonCol>
					</IonRow>
				</IonGrid>
				<IonLoading
					isOpen={isLoading === true}
					message="Please Wait"
				></IonLoading>
			</IonContent>
		</IonPage>
	);
};

export default MyStoreDetails;
