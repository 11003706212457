import React from 'react';
import { IonImg } from '@ionic/react';
import ClClogo from '../../assets/logo/clc-logo.png';

interface LogoImageProps {
	width?: string;
}

const LogoImage: React.FC<LogoImageProps> = ({ width }) => {
	const style = width ? { width: `${width}px` } : {};
	// console.log(style);
	return <IonImg src={ClClogo} style={style} />;
};

export default LogoImage;
