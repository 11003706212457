import {
	IonButton,
	IonCol,
	IonContent,
	IonHeader,
	IonIcon,
	IonImg,
	IonPage,
	IonRow,
	IonTitle,
	IonToolbar,
} from '@ionic/react';

import { create, trash } from 'ionicons/icons';
import React, { useState } from 'react';
import UpdateProductModal from './UpdateProductModal';
import { Link } from 'react-router-dom';

interface SingleProductRowProps {
	product: any;
}

const SingleProductRow: React.FC<SingleProductRowProps> = ({ product }) => {
	const [showModal, setShowModal] = useState(false);
	const handleOpenModal = () => {
		setShowModal(true);
	};

	const updateHandleCloseModal = () => {
		setShowModal(false);
	};
	return (
		<IonRow
			key={product.id}
			className="ionrow-style ion-align-items-center ion-text-center"
			style={{ height: '80px' }}
		>
			{/* <IonCol className="ioncol-style" sizeMd="1" sizeSm="2">
				
				{product.id}
			</IonCol> */}

			<IonCol className="ioncol-style ion-hide-md-down" sizeMd="2">
				<div>
					<IonImg
						style={{
							width: '100%',
							height: '50px',
						}}
						src={product?.image_display_url}
					/>
				</div>
				{/* {row?.current_subscription.end_date != null
									? new Date(row?.current_subscription?.end_date)
											.toDateString()
											.split(' ')
											.slice(1)
											.join(' ')
									: 'null'} */}
			</IonCol>
			<IonCol className="ioncol-style" sizeMd="4" sizeSm="5">
				{/* {row.name} */}
				{product.name}
				{/* <br /> */}
				{/* {row?.subdomain !== null && (
									<a
										style={{ textDecoration: 'none' }}
										href={`https://${row?.subdomain}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										<IonButton size="small" fill="outline">
											<IonIcon
												slot="icon-only"
												size="small"
												icon={link}
											></IonIcon>
										</IonButton>
									</a>
								)} */}
			</IonCol>
			<IonCol className="ioncol-style ion-hide-md-down" sizeMd="1">
				{/* {row?.onboarding.amount} */}
				{product?.dimension}
			</IonCol>
			<IonCol className="ioncol-style" sizeMd="1" sizeSm="5">
				{product.internal_notes}
			</IonCol>
			<IonCol className="ioncol-style" sizeMd="2" sizeSm="5">
				{product.status}
				{/* <IonText className="ion-padding">
									{row?.city == 'undefined' || row?.city == null
										? 'null'
										: `${row?.city}`}
								</IonText>
								<br />
								{row?.phone === null ? null : (
									<ContactButton data={row?.phone} btn="Call" />
								)}
								{row?.email === null ? null : (
									<ContactButton data={row?.email} btn="Mail" />
								)} */}
			</IonCol>

			{/* <IonCol className="ioncol-style ion-hide-md-down" sizeMd="1.5">
				
				{product?.weight_in_gms}
			</IonCol> */}

			{/* <IonCol className="ioncol-style ion-hide-md-down" sizeMd="1.5">
				{product?.laundry_service_price}
				{row?.onboarding.date != null
									? new Date(row?.onboarding.date)
											.toDateString()
											.split(' ')
											.slice(1)
											.join(' ')
									: 'null'}
			</IonCol> */}

			<IonCol className="ioncol-style ion-hide-md-down" sizeMd="2">
				{/* {row?.onboarding.amount} */}
				<Link to={`/app/products/update/${product.id}`}>
					<IonButton
						size="small"
						fill="outline"
						onClick={() => {
							console.log('edit', product.id);
						}}
					>
						<IonIcon slot="icon-only" size="small" icon={create}></IonIcon>
					</IonButton>
				</Link>
				{/* <IonButton size="small" fill="outline">
					<IonIcon slot="icon-only" size="small" icon={trash}></IonIcon>
				</IonButton> */}
			</IonCol>

			{/* <UpdateProductModal
				product={product}
				handleModalClose={updateHandleCloseModal}
				showModal={showModal}
			/> */}
		</IonRow>
	);
};

export default SingleProductRow;
