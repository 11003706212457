import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { SplashScreen } from '@capacitor/splash-screen';
import { isMobile } from "react-device-detect";
import store from './store';

import {Provider} from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
    <BrowserRouter>
        <Provider store = {store}>
            <App />
        </Provider>
    </BrowserRouter>
    , document.getElementById('root'), ()=>{
        isMobile ? SplashScreen.show() : SplashScreen.hide();
});
