export const GET_PAGES_REQUEST = 'GET_PAGES_REQUEST'
export const GET_PAGES_SUCCESS = 'GET_PAGES_SUCCESS'
export const GET_PAGES_FAIL = 'GET_PAGES_FAIL'

export const GET_PAGE_BY_ID_REQUEST = 'GET_PAGE_BY_ID_REQUEST'
export const GET_PAGE_BY_ID_SUCCESS = 'GET_PAGE_BY_ID_SUCCESS'
export const GET_PAGE_BY_ID_FAIL = 'GET_PAGE_BY_ID_FAIL'

export const CREATE_PAGE_REQUEST = 'CREATE_PAGE_REQUEST'
export const CREATE_PAGE_SUCCESS = 'CREATE_PAGE_SUCCESS'
export const CREATE_PAGE_FAIL = 'CREATE_PAGE_FAIL'

export const UPDATE_PAGE_REQUEST = 'UPDATE_PAGE_REQUEST'
export const UPDATE_PAGE_SUCCESS = 'UPDATE_PAGE_SUCCESS'
export const UPDATE_PAGE_FAIL = 'UPDATE_PAGE_FAIL'

export const CHECK_URL_AVAILABILITY_REQUEST = 'CHECK_URL_AVAILABILITY_REQUEST'
export const CHECK_URL_AVAILABILITY_SUCCESS = 'CHECK_URL_AVAILABILITY_SUCCESS'
export const CHECK_URL_AVAILABILITY_FAIL = 'CHECK_URL_AVAILABILITY_FAIL'

export const DELETE_PAGE_REQUEST = 'DELETE_PAGE_REQUEST'
export const DELETE_PAGE_SUCCESS = 'DELETE_PAGE_SUCCESS'
export const DELETE_PAGE_FAIL = 'DELETE_PAGE_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'