import React,{useState,useContext} from 'react';
import {
	IonTabs,
	IonRouterOutlet,
	IonTabBar,
	IonTabButton,
	IonIcon,
	IonLabel,
} from '@ionic/react';
import { close, triangle, square } from 'ionicons/icons';

import { Route, Redirect } from 'react-router';
import {
	calendar,
	location,
	informationCircle,
	people,
	home,
	homeSharp,
	appsSharp,
	settingsSharp,
	cashSharp,
	storefrontOutline,
	peopleOutline,
	fileTrayOutline,
	cardOutline
} from 'ionicons/icons';
import Dashboard from './dashboard/Dashboard';
import IKAcademy from './dashboard/IKAcademy/IKAcademy';
import Subscriptions from './dashboard/subscriptions/Subscriptions';
import Customer from './dashboard/Customer/Customer';
import Coupons from './dashboard/coupons/Coupons';
import CreateCouponPage from './dashboard/coupons/CreateCouponPage';
import Banners from './dashboard/banners/Banners';
import Settings from './dashboard/settings/Settings';
import BasicInfo from './dashboard/settings/BasicInfo';
import Localization from './dashboard/settings/Localization';
import ServiceDetails from './dashboard/settings/ServiceDetails';
import Payments from './dashboard/settings/Payments';
import QuickOrders from './dashboard/settings/QuickOrders';
import Taxes from './dashboard/settings/taxes/Taxes';
import Orders from './dashboard/orders/Orders';
import Marketing from './dashboard/marketing/Marketing';
import CreateStaffPage from './dashboard/staff/CreateStaffPage';
import Staffs from './dashboard/staff/Staff';
import CreateTaxPage from './dashboard/settings/taxes/CreateTaxes';
// import Home from './auth/Home/Home';
// import GetBusinessDetails from './auth/GetBusinessDetails';
// import SignUpWithEmailOTP from './auth/SignUpWithEmailOTP/SignUpWithEmailOTP';
import Catalogue from './pages/Catalogue';
import UpdateCategoryPage from './pages/UpdateCategory';
import AddCategoryPage from './pages/AddCategory';
import OrderDetails from './dashboard/orders/OrderDetails';
import ProtectedRoute from './route/ProtectedRoute';
import AddProduct from './pages/AddProduct';
import AddSubCategoryPage from './pages/AddSubCategory';
import UpdateSubCategoryPage from './pages/UpdateSubCategory';
import UpdateProduct from './pages/UpdateProduct';
import ProductsHomeScreen from './components/products/ProductsHomeScreen';
import HelpAndSupport from './dashboard/HelpAndSupport/HelpAndSupport';
import Invoice from './pages/Invoice';
import AddBanner from './dashboard/banners/AddBanner';
import PageListing from './dashboard/page/PageListing';
import CreatePage from './dashboard/page/CreatePage';
import UpdatePage from './dashboard/page/UpdatePage';
import Stores from './components/stores/Stores';
import BankAccountDetails from './dashboard/settings/BankAccountDetails';
import MyStoreDetails from './components/stores/MyStoreDetails';
import Support from './dashboard/HelpAndSupport/Support';
import Features from './components/dashboard/Features';
import Leads from './dashboard/leads/Leads';
import CreateLeadsPage from './dashboard/leads/CreateLeadsPage';
import LandingPages from './dashboard/landingpages/LandingPages';
import Reports from './dashboard/reports/Reports';
import DashboardHome from './dashboard/Home/DashboardHome';
import Tab from './components/order/Tab';
import Tab1 from './components/order/tab1';
import Tab2 from './components/order/tab2';
import OrderState from './components/order/orderState';
import orderContext from './components/order/orderContext';
import AddOrder from './pages/AddOrder';


interface MainTabsProps {}



const MainTabs: React.FC<MainTabsProps> = () => {

	
    const { isTabBarHidden, handleTabButtonClick } = useContext(orderContext);

 
	return (

<>
<OrderState>
<IonTabs>
		<IonRouterOutlet>
				<ProtectedRoute path="/app/home" component={Dashboard} exact={true} />
				
				<ProtectedRoute
					path="/app/orders/create"
					component={AddOrder}
					exact={true}
				/>
				<Route exact path="/my/catalogue/category/add/:fp">
					<AddCategoryPage />
				</Route>
				<Route exact path="/app/subcategory/add/:id/:name/:fp">
					<AddSubCategoryPage />
				</Route>
				
				<ProtectedRoute
					path="/app/products"
					component={ProductsHomeScreen}
					exact={true}
				/>
				<ProtectedRoute path="/app/stores" component={Stores} exact={true} />
								
				<ProtectedRoute
					path="/app/products/update/:id"
					component={UpdateProduct}
					exact={true}
				/>
				<ProtectedRoute
					path="/app/products/create/:fp"
					component={AddProduct}
					exact={true}
				/>
				
				

<ProtectedRoute path="/my/stores" component={Stores} exact={true} />
				<ProtectedRoute
					path="/my/stores/store-details/:id"
					component={MyStoreDetails}
					exact={true}
				/>
				<ProtectedRoute
					path="/app/customers"
					component={<></>}
					exact={true}
				/>
{/* Added by Saubhagya Singh */}
				<ProtectedRoute
					path="/app/orders"
					component={Tab}
					
   exact={true}
					
				/>
				<ProtectedRoute
					path="/app/orders/act"
					component={Tab1}
					exact={true}
				/>
				<ProtectedRoute
					path="/app/orders/all"
					component={Tab2}
					exact={true}
				/>
				
				{/* end added */}
			</IonRouterOutlet>

			{
			(!isTabBarHidden) && ( 
			<IonTabBar slot="bottom"  style={{ height: '0px' }}>
	 
			{/* <IonTabButton tab="schedule" href="/app/products"
			onClick={() => handleTabButtonClick('products')}>
					<IonIcon icon={fileTrayOutline} />
					<IonLabel>Products</IonLabel>
				</IonTabButton>

		 	<IonTabButton tab="stores" href="/app/customer"
			onClick={() => handleTabButtonClick('customers')}>
					<IonIcon icon={peopleOutline} />
					<IonLabel>Customers</IonLabel>
				</IonTabButton>
				
					<IonTabButton tab="orders" href="/app/orders/tab1" onClick={() => handleTabButtonClick('orders')}>
					<IonIcon icon={cardOutline} />
					<IonLabel>Orders</IonLabel>
				</IonTabButton> */}
			</IonTabBar> 
			 )}
			
			{
			
			( isTabBarHidden) && (  <IonTabBar slot="bottom"  style={{ height: '0px' }}>
            {/* <IonTabButton tab="tab1" href="/app/orders/act"
			>
                          <IonIcon icon={triangle}/>
                          <IonLabel>
                            ACT ss
                          </IonLabel>
                          
            </IonTabButton> */}

            {/* <IonTabButton tab="tab2" href="/app/orders/all">
                          <IonIcon icon={square} />
                          <IonLabel>
                            ALL 
                          </IonLabel>
                          
            </IonTabButton> */}
           </IonTabBar>        )}


		</IonTabs>
</OrderState>

		
		</>
	);
};

export default MainTabs;
