import { CLEAR_ERRORS, 
    CREATE_PAGE_REQUEST, CREATE_PAGE_SUCCESS, CREATE_PAGE_FAIL,
    GET_PAGES_REQUEST, GET_PAGES_SUCCESS, GET_PAGES_FAIL,
    GET_PAGE_BY_ID_REQUEST, GET_PAGE_BY_ID_SUCCESS, GET_PAGE_BY_ID_FAIL,
    UPDATE_PAGE_REQUEST, UPDATE_PAGE_SUCCESS, UPDATE_PAGE_FAIL,
    CHECK_URL_AVAILABILITY_REQUEST, CHECK_URL_AVAILABILITY_SUCCESS, CHECK_URL_AVAILABILITY_FAIL,
    DELETE_PAGE_REQUEST, DELETE_PAGE_SUCCESS, DELETE_PAGE_FAIL
} from "../constants/pageConstants";

export const loadPagesReducer = (state = { pages: {} }, action: any) =>{
    switch(action.type){
        case GET_PAGES_REQUEST:
            return{
                ...state,
                getPagesLoading: true,
                getPagesError: null
            }
        case GET_PAGES_SUCCESS:
            return{
                ...state,
                getPagesLoading: false,
                pages: action.payload
            }
        case GET_PAGES_FAIL:
            return{
                ...state,
                getPagesLoading: false,
                getPagesError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadPageByIdReducer = (state = { pageById: {} }, action: any) =>{
    switch(action.type){
        case GET_PAGE_BY_ID_REQUEST:
            return{
                ...state,
                getPageByIdLoading: true,
                getPageByIdError: null
            }
        case GET_PAGE_BY_ID_SUCCESS:
            return{
                ...state,
                getPageByIdLoading: false,
                pageById: action.payload
            }
        case GET_PAGE_BY_ID_FAIL:
            return{
                ...state,
                getPageByIdLoading: false,
                getPageByIdError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const createPageReducer = (state = { createPageMessage: {} }, action: any) =>{
    switch(action.type){
        case CREATE_PAGE_REQUEST:
            return{
                ...state,
                createPageLoading: true,
                createPageError: null
            }
        case CREATE_PAGE_SUCCESS:
            return{
                ...state,
                createPageLoading: false,
                createPageMessage: action.payload
            }
        case CREATE_PAGE_FAIL:
            return{
                ...state,
                createPageLoading: false,
                createPageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const checkURLAvailabilityReducer = (state = { isURLAvailable: null }, action: any) =>{
    switch(action.type){
        case CHECK_URL_AVAILABILITY_REQUEST:
            return{
                ...state,
                checkAvailabilityLoading: true,
                checkAvailabilityError: null
            }
        case CHECK_URL_AVAILABILITY_SUCCESS:
            return{
                ...state,
                checkAvailabilityLoading: false,
                isURLAvailable: action.payload
            }
        case CHECK_URL_AVAILABILITY_FAIL:
            return{
                ...state,
                checkAvailabilityLoading: false,
                checkAvailabilityError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const updatePageReducer = (state = { updatePageMessage: {} }, action: any) =>{
    switch(action.type){
        case UPDATE_PAGE_REQUEST:
            return{
                ...state,
                updatePageLoading: true,
                updatePageError: null
            }
        case UPDATE_PAGE_SUCCESS:
            return{
                ...state,
                updatePageLoading: false,
                updatePageMessage: action.payload
            }
        case UPDATE_PAGE_FAIL:
            return{
                ...state,
                updatePageLoading: false,
                updatePageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const deletePageReducer = (state = { deletePageMessage: {} }, action: any) =>{
    switch(action.type){
        case DELETE_PAGE_REQUEST:
            return{
                ...state,
                deletePageLoading: true,
                deletePageError: null
            }
        case DELETE_PAGE_SUCCESS:
            return{
                ...state,
                deletePageLoading: false,
                deletePageMessage: action.payload
            }
        case DELETE_PAGE_FAIL:
            return{
                ...state,
                deletePageLoading: false,
                deletePageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}