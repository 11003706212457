import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonViewDidEnter,
} from '@ionic/react';
import {
  arrowBack as arrowBackIcon, logoYoutube
} from 'ionicons/icons';
import { Camera,CameraResultType } from '@capacitor/camera';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import { getAccessToken } from '../services/auth/userLocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import { createStoresCategory, getStoresCategory} from '../actions/categoryAction';

async function savePicture(file) {
  if(!file)return "";
  const formData = new FormData();
  formData.append("file",file)
  try{
    const response = await fetch(`https://api.intelikart.com/api/upload/category-image`, {
      method: 'POST',
      body:formData
    });
    const data = await response.json();
    return data.platform_path;
  }
  catch(error){
    return "";
  }
}


const AddCategoryPage: React.FC = (props: any) => {
  //const { userId } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const {fp}=useParams<any>();
  const { currentUser } = useSelector((state : any)=> state.currentUser);
  const { fetchCategoriesLoading, categories, fetchCategoriesError } = useSelector((state : any)=> state.categories);
  const { createCategoryLoading, createCategory, createCategoryError } = useSelector((state : any)=> state.categories);
  const [date, setDate] = useState('');
  const [categoryName, setCategoryName] = useState(''); 
  const [pictureUrl, setPictureUrl] = useState('/assets/placeholder.svg');
  const[status,setStatus]=useState('active')
  const[imageFile,setImageFile]=useState(null)
  const fileInputRef = useRef<HTMLInputElement>();
  const categoryNameInputRef = useRef(null);
  const[loading,setLoading]=useState(false);

  // useEffect( () => {
  //       if(!createCategoryLoading && createCategory){
  //         //dispatch(getStoresCategory(accessToken,currentUser.data[0].store_id))
  //         history.push(`/subcategory/add/${createCategory.id}`)
  //       }
  // },[createCategoryLoading,createCategory]) 
  useEffect(()=>{
      const accessToken = getAccessToken();
      if(accessToken && currentUser && currentUser.data && !categories)
          dispatch(getStoresCategory(accessToken,currentUser.data[0].store_id));
  },[currentUser]); 
  useEffect(()=>{
     if(createCategory){
      history.push(`/my/catalogue/subcategory/add/${createCategory.id}/${createCategory.name}/${fp}`)
    }
  },[createCategory])

  useIonViewDidEnter(() => {
    setTimeout(() => categoryNameInputRef.current.setFocus(), 100)
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files.length > 0) {
      
      const file = event.target.files.item(0);
      setImageFile(file)
      const pictureUrl = URL.createObjectURL(file);
      setPictureUrl(pictureUrl);
    }
  };
  const handlePictureClick= async()=>{
    
    if(isPlatform('capacitor')){
      try{
        const photo=await Camera.getPhoto({
          resultType:CameraResultType.Uri,
        })
        setPictureUrl(photo.webPath);
      }
      catch(error){
        console.log(error);
      }
    }
    else{
      fileInputRef.current.click();
    }
    
  }
  
  
  const handleSave=async ()=>{
      
      const accessToken = await getAccessToken();
       if(accessToken && currentUser && currentUser.data){
        setLoading(true)
        const platform_path= await savePicture(imageFile)
        await dispatch(createStoresCategory(accessToken, currentUser.data[0].store_id, {
          "id": 0,
          "parent_id":0,
          "name": categoryName,
          "store_id": `${currentUser.data[0].store_id}`,
          "priority": '',
          "icon_code": platform_path,
          "status": status
        }));
        setCategoryName("")
        // setParentCategory(0)
        // setPictureUrl('/assets/placeholder.svg')
        // setImageFile(null)
        setLoading(false)
        // history.push("/my/catalogue")
    }
  }
   
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
          <IonIcon size="large" onClick={e=> history.goBack()} icon={arrowBackIcon}/>
          </IonButtons>
          <IonTitle>Add Category</IonTitle>
          <IonButtons slot="end">
            <IonButton  size="small" target="_blank" href="https://www.youtube.com/watch?v=dkOvPjF41YA" color='medium'>
                  <IonIcon slot="start" icon={logoYoutube}></IonIcon>
                    How to Use</IonButton>
          </IonButtons> 
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
          <IonItem >
            <IonLabel position="stacked">Category Name</IonLabel>
            <IonInput required value={categoryName}  ref={(ref) => categoryNameInputRef.current = ref} placeholder={"Add Category Example Fruits" }
              onIonChange={(event) => setCategoryName(event.detail.value)}
            />
          </IonItem>
        <div className="ion-text-center ion-padding">
        <IonButton shape="round" disabled={categoryName==""?true:false} onClick={handleSave}>Next</IonButton>
        </div>
      <IonLoading isOpen={ loading} message={ 'Adding category'}/>
      </IonContent>
    </IonPage>
  );
};

export default AddCategoryPage;