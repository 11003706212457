import { IonContent, IonPage } from '@ionic/react';

import React from 'react';
import Header from '../dashboard/Header';
import MyProductTable from '../products/MyProductTable';
// import MyTable from './MyTable';

const Stores = () => {
	return (
		<IonPage>
			<Header name="" />
			{/* <MyProductTable /> */}
		</IonPage>
	);
};

export default Stores;
