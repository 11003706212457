import { CLEAR_ERRORS } from "../constants/authConstants"
import { ACTION_CREATE_STORE_CATEGORY_FAIL, ACTION_CREATE_STORE_CATEGORY_REQUEST, ACTION_CREATE_STORE_CATEGORY_SUCCESS, ACTION_CREATE_STORE_SUBCATEGORY_FAIL, ACTION_CREATE_STORE_SUBCATEGORY_REQUEST, ACTION_CREATE_STORE_SUBCATEGORY_SUCCESS, ACTION_DELETE_STORE_CATEGORY_FAIL, ACTION_DELETE_STORE_CATEGORY_REQUEST, ACTION_DELETE_STORE_CATEGORY_SUCCESS, ACTION_EDIT_STORE_CATEGORY_FAIL, ACTION_EDIT_STORE_CATEGORY_REQUEST, ACTION_EDIT_STORE_CATEGORY_SUCCESS, ACTION_STORE_CATEGORIES_FAIL, ACTION_STORE_CATEGORIES_REQUEST, ACTION_STORE_CATEGORIES_SUCCESS, ACTION_UPLOAD_FILE_FAIL, ACTION_UPLOAD_FILE_REQUEST, ACTION_UPLOAD_FILE_SUCCESS } from "../constants/category"

export const loadCategoriesReducer = (state = { categories: undefined }, action: any) =>{
    switch(action.type){
    
        case ACTION_STORE_CATEGORIES_REQUEST:
            return{
                ...state,
                fetchCategoriesLoading: true,
                fetchCategoriesError: null
            }
        case ACTION_STORE_CATEGORIES_SUCCESS:
            return{
                ...state,
                fetchCategoriesLoading: false,
                categories: action.payload,
                createCategory:null
            }
        case ACTION_STORE_CATEGORIES_FAIL:
            return{
                ...state,
                fetchCategoriesLoading: false,
                fetchCategoriesError: action.payload
            }

        case ACTION_CREATE_STORE_CATEGORY_REQUEST:
            return{
                ...state,
                createCategoryLoading: true,
                createCategoryError: null
            }
        case ACTION_CREATE_STORE_CATEGORY_SUCCESS: 
            return{
                ...state,
                createCategoryLoading: false,
                createCategory: action.payload
            }
        case ACTION_CREATE_STORE_CATEGORY_FAIL:
            return{
                ...state,
                createCategoryLoading: false,
                createCategoryError: action.payload
            }

        case ACTION_CREATE_STORE_SUBCATEGORY_REQUEST:
            return{
                ...state,
                createSubCategoryLoading: true,
                createSubCategoryError: null
            }
        case ACTION_CREATE_STORE_SUBCATEGORY_SUCCESS: 
            return{
                ...state,
                createSubCategoryLoading: false,
                createSubCategory: action.payload
            }
        case ACTION_CREATE_STORE_SUBCATEGORY_FAIL:
            return{
                ...state,
                createSubCategoryLoading: false,
                createSubCategoryError: action.payload
            }

        case ACTION_EDIT_STORE_CATEGORY_REQUEST:
            return{
                ...state,
                editCategoryLoading: true,
                editCategoryError: null
            }
        case ACTION_EDIT_STORE_CATEGORY_SUCCESS:
            return{
                ...state,
                editCategoryLoading: false,
                editCategory: action.payload
            }
        case ACTION_EDIT_STORE_CATEGORY_FAIL:
            return{
                ...state,
                editCategoryLoading: false,
                editCategoryError: action.payload
            }

        
        case ACTION_DELETE_STORE_CATEGORY_REQUEST:
            return{
                ...state,
                deleteCategoryLoading: true,
                deleteCategoryError: null
            }
        case ACTION_DELETE_STORE_CATEGORY_SUCCESS:
            return{
                ...state,
                deleteCategoryLoading: false,
                deleteCategoryError: action.payload
            } 
        case ACTION_DELETE_STORE_CATEGORY_FAIL:
            return{
                ...state,
                deleteCategoryLoading: false,
                deleteCategoryError: action.payload
            }
        
        default:
            return state
    }
}