import {
	IonCol,
	IonGrid,
	IonRow,
	IonLoading,
	IonFooter,
	IonButton,
	IonIcon,
	IonContent,
	IonText,
	IonItem,
	IonToolbar,
	IonTitle,
	IonHeader,
	IonLabel,
	IonInput,
	IonModal,
	useIonToast,
	IonImg,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import { API_ROOT } from '../../constants/global';
import { useHistory, Route, Redirect } from 'react-router-dom';
import {
	arrowForwardOutline as rightArrowIcon,
	arrowBackOutline as leftArrowIcon,
	link,
	informationCircle,
	closeOutline as closeOutlineIcon,
	eyedropOutline,
	create,
	trashBin,
	trash,
} from 'ionicons/icons';
import { ContactButton } from '../stores/ContactButton';
import { async } from 'q';
import { storeClaim } from '../../actions/dashboardActions';
import { useDispatch, useSelector } from 'react-redux';
import UpdateProductModal from './UpdateProductModal';
import SingleProductRow from './SingleProductRow';

interface MyProductTableProps {
	products: any;
}

const MyProductTable: React.FC<MyProductTableProps> = ({ products }) => {
	const history = useHistory();
	const [nextPage, setNextPage] = useState();
	const [previousPage, setPreviousPage] = useState();

	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [present] = useIonToast();
	const [subdomain, setSubdomain] = useState('');
	const [errors, setErrors] = useState({ phone: '', email: '', subdomain: '' });
	const [flag, setFlag] = useState(false);
	const dispatch = useDispatch();

	// const {
	//     createClaimMessageLoading,
	//     createClaimMessage,
	//     createClaimMessageError,
	// } = useSelector((state: any) => state.storeClaim);

	// const handleCloseModal = async () => {
	// let errors = {
	// 	phone: '',
	// 	email: '',
	// 	subdomain: '',
	// };
	// 	if (!isValidEmail(email)) {
	// 		errors.email = 'please enter a valid email';
	// 	}
	// 	// if (phone.length !== 10) {
	// 	// 	errors.phone = 'please enter a valid phone number';
	// 	// }
	// 	setErrors(errors);
	// 	if (Object.values(errors).every(error => !error)) {
	// 		try {
	// 			await dispatch(
	// 				storeClaim(getAccessToken().token, {
	// 					phone,
	// 					email,
	// 					subdomain,
	// 				})
	// 			);
	// 			setFlag(!flag);
	// 		} catch (e) {
	// 			console.log(e);
	// 		}
	// 	}
	// };
	// useEffect(()=>{
	//     if(Object.keys(createClaimMessage).length>0){
	//         present({
	//             message:createClaimMessage,
	//             duration: 2000,
	//             position: "bottom",
	//           });
	//           setShowModal(false);
	//     }
	// },[flag])
	// console.log('in product table');
	// const isValidEmail = email => {
	// 	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	// 	return emailRegex.test(email);
	// };

	// console.log(details);

	// const handleNextPageClick = async () => {
	// 	const token = getAccessToken();
	// 	setLoading(true);
	// 	let response = await fetch(nextPage, {
	// 		method: 'GET',
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 			authorization: `Bearer ${token.token}`,
	// 		},
	// 	});

	// 	response
	// 		.json()
	// 		.then(async res => {
	// 			if (res && res.data && res.data.length > 0) {
	// 				setDetails(res?.data);
	// 				setNextPage(res?.meta.pagination.links.next);
	// 				setPreviousPage(res?.meta.pagination.links.previous);
	// 			}
	// 		})
	// 		.catch(err => console.error(err));
	// 	setLoading(false);
	// };

	// const handlePreviousPageClick = async () => {
	// 	const token = getAccessToken();
	// 	setLoading(true);
	// 	let response = await fetch(previousPage, {
	// 		method: 'GET',
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 			authorization: `Bearer ${token.token}`,
	// 		},
	// 	});

	// 	response
	// 		.json()
	// 		.then(async res => {
	// 			if (res && res.data && res.data.length > 0) {
	// 				setDetails(res?.data);
	// 				setNextPage(res?.meta.pagination.links.next);
	// 				setPreviousPage(res?.meta.pagination.links.previous);
	// 			}
	// 		})
	// 		.catch(err => console.error(err));
	// 	setLoading(false);
	// };
	// console.log(products);
	return (
		<>
			{/* <IonContent> */}
			<IonGrid className="ion-margin-top" style={{ color: '#353535' }}>
				{/* <div className="ion-text-right ion-margin">
					<IonButton onClick={handleOpenModal}>Store claim</IonButton>
					<IonModal isOpen={showModal} onDidDismiss={updateHandleCloseModal}>
						<IonHeader>
							<IonToolbar>
								<IonTitle>Enter Store Claim Information</IonTitle>

								<IonText
									slot="end"
									color="primary"
									onClick={e => {
										setShowModal(false);
									}}
									style={{ cursor: 'pointer' }}
								>
									<h1 className="ion-padding-end">
										<IonIcon
											slot="icon-only"
											size="large"
											icon={closeOutlineIcon}
										></IonIcon>
									</h1>
								</IonText>
							</IonToolbar>
						</IonHeader>

						<IonContent className="ion-padding">
							<IonItem>
								<IonLabel position="floating">Phone Number</IonLabel>
								<IonInput
									value={phone}
									onIonChange={e => setPhone(e.detail.value)}
								></IonInput>
							</IonItem>
							<div style={{ marginTop: '20px', marginBottom: '10px' }}>
								{errors.phone}
							</div>
							<IonItem>
								<IonLabel position="floating">Email</IonLabel>
								<IonInput
									value={email}
									onIonChange={e => setEmail(e.detail.value)}
								></IonInput>
							</IonItem>
							<div> {errors.email} </div>
							<IonItem>
								<IonLabel position="floating">Subdomain</IonLabel>
								<IonInput
									value={subdomain}
									onIonChange={e => setSubdomain(e.detail.value)}
								></IonInput>
							</IonItem>
							<div className="ion-text-center">
								<IonButton
									shape="round"
									className="ion-margin"
									onClick={handleCloseModal}
								>
									Save
								</IonButton>
							</div>
						</IonContent>
					</IonModal>
				</div> */}
				{/* <IonRow
					className="ionrow-style ion-align-items-center ion-text-center"
					style={{ padding: 10 + 'px' }}
				>
					<IonCol className="ioncol-style" sizeMd="6" sizeSm="12">
						<b>STORE DETAILS</b>
					</IonCol>
					<IonCol className="ioncol-style ion-hide-md-down" sizeMd="3">
						<b>CURRENT SUB</b>
					</IonCol>
					<IonCol className="ioncol-style ion-hide-md-down" sizeMd="3">
						<b>REVENUES</b>
					</IonCol>
				</IonRow> */}
				<IonRow
					className="ionrow-style ion-align-items-center ion-text-center"
					style={{ backgroundColor: 'white', padding: 10 + 'px' }}
				>
					{/* <IonCol className="ioncol-style" sizeMd="1" sizeSm="2">
						<b>ID</b>
					</IonCol> */}
					<IonCol className="ioncol-style" sizeMd="2">
						<b>Image</b>
					</IonCol>
					<IonCol className="ioncol-style" sizeMd="4">
						<b>Name</b>
					</IonCol>
					<IonCol className="ioncol-style" sizeMd="1">
						<b>Dimension</b>
					</IonCol>
					<IonCol className="ioncol-style" sizeMd="1">
						<b>Internal Notes</b>
					</IonCol>
					<IonCol className="ioncol-style" sizeMd="2">
						<b>Status</b>
					</IonCol>
					<IonCol className="ioncol-style" sizeMd="2">
						<b>Action</b>
					</IonCol>
					{/* <IonCol className="ioncol-style ion-hide-md-down" sizeMd="1.5">
						<b>Price</b>
					</IonCol> */}
				</IonRow>

				{products?.map(pd => {
					return <SingleProductRow key={pd.id} product={pd} />;
				})}
			</IonGrid>

			{/* </IonContent> */}
			{/* <IonFooter>
				<IonGrid>
					<IonRow>
						{previousPage ? (
							<IonCol>
								<div className="ion-text-left">
									<IonButton
										fill="outline"
										// onClick={() => handlePreviousPageClick()}
									>
										<IonIcon
											slot="start"
											size="medium"
											icon={leftArrowIcon}
										></IonIcon>
										Previous
									</IonButton>
								</div>
							</IonCol>
						) : null}

						{nextPage ? (
							<IonCol>
								<div className="ion-text-right">
									<IonButton
										fill="outline"
										// onClick={() => handleNextPageClick()}
									>
										Next
										<IonIcon
											slot="end"
											size="medium"
											icon={rightArrowIcon}
										></IonIcon>
									</IonButton>
								</div>
							</IonCol>
						) : null}
					</IonRow>
				</IonGrid>
			</IonFooter> */}
		</>
	);
};

export default MyProductTable;
