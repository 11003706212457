import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	IonContent,
	IonPage,
	IonItem,
	IonLabel,
	IonButton,
	IonInput,
	IonText,
	IonSelectOption,
	IonSelect,
	IonLoading,
	IonIcon,
	IonCol,
	IonGrid,
	IonRow,
	IonModal,
	IonImg,
	IonHeader,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonBackButton,
	useIonViewDidEnter,
	IonTextarea,
	IonAlert,
} from '@ionic/react';
import LogoImage from '../components/auth/LogoImage';
// import {
//     getBusinessTypeList,
//     onboardingUser,
//     clearErrors,
// } from "../actions/authActions";
import { getAccessToken } from '../services/auth/userLocalStorage';
// import {
//     caretDown as caretDownIcon,
//     checkmarkSharp as checkmarkSharpIcon,
//     closeOutline,
// } from "ionicons/icons";
// import LoginFooter from "../components/auth/LoginFooter";
import { isDesktop } from 'react-device-detect';
import { API_ROOT } from '../constants/global';

// interface businesstypeItem {
//     id: number;
//     name: string;
//     icon: string;
//     description: string;
// }

const GetBusinessDetails = () => {
	// const [showModal, setShowModal] = useState(false);

	// const [storeType, setStoreType] = useState<any>();

	// const [businessURL, setBusinessURL] = useState<undefined | string | null>(
	//     ""
	// );
	// const [isBusinessTypeEmpty, setIsBusinessTypeEmpty] = useState<
	//     boolean | undefined
	// >(false);
	const [name, setName] = useState<undefined | string | null>('');
	const [phone, setPhone] = useState<undefined | string | null>('');
	const [city, setCity] = useState<any>();
	const [state, setState] = useState<any>();
	const [ZIP, setZIP] = useState<any>();
	const [selectedValue, setSelectedValue] = useState<any>('1-10');
	const [about, setAbout] = useState<string | null | undefined>('');
	const [uniqueCode, setUniqueCode] = useState<string | null | undefined>(
		Math.random().toString(36).slice(2, 7).toUpperCase()
	);

	//required fields
	const [isPhoneEmpty, setIsPhoneEmpty] = useState<undefined | boolean>(false);
	const [isNameEmpty, setIsNameEmpty] = useState<undefined | boolean>(false);
	const [isCityEmpty, setIsCityEmpty] = useState<undefined | boolean>(false);
	const [isStateEmpty, setIsStateEmpty] = useState<undefined | boolean>(false);
	const [isZipEmpty, setIsZipEmpty] = useState<undefined | boolean>(false);
	const [isSelectedValueEmpty, setIsSelectedValueEmpty] = useState<
		undefined | boolean
	>(false);
	const [isUniqueCodeEmpty, setIsUniqueCodeEmpty] = useState<
		undefined | boolean
	>(false);
	// const [isBusinessURLEmpty, setIsBusinessURLEmpty] = useState<
	//     undefined | boolean
	// >(false);

	//vallidation
	const [isPhoneValidate, setIsPhoneValidate] = useState<undefined | boolean>(
		true
	);
	const [isNameValidate, setIsNameValidate] = useState<undefined | boolean>(
		true
	);
	const [isZipValidate, setIsZipValidate] = useState<undefined | boolean>(true);
	const [isUniqueCodeValidate, setIsUniqueCodeValidate] = useState<
		undefined | boolean
	>(true);
	const [isUniqueCodeAvailable, setIsUniqueCodeAvailable] = useState<
		undefined | boolean
	>(true);
	// const [isBusinessURLValidate, setIsBusinessURLValidate] = useState<
	//     undefined | boolean
	// >(true);
	// const [isBusinessURLAvailable, setIsBusinessURLAvailable] = useState<
	//     undefined | boolean
	// >(true);

	//create account loading
	const [loading, setLoading] = useState<undefined | boolean>(false);

	const history = useHistory();
	const dispatch = useDispatch();
	// const { businessTypeListLoading, businessTypeList, businessTypeListError } =
	//     useSelector((state: any) => state.businessTypeList);
	// const { currentUser } = useSelector((state: any) => state.currentUser);
	const { userOnboardingLoading, userOnboardingMessage, userOnboardingError } =
		useSelector((state: any) => state.userOnboarding);

	const [showAlert, setShowAlert] = useState(false);

	// const token = getAccessToken();

	// useEffect(() => {
	//     (async function () {
	//         await dispatch(getBusinessTypeList());
	//     })();
	//     if (!businessTypeListLoading && businessTypeList) {
	//     }
	//     if (!businessTypeListLoading && businessTypeListError) {
	//     }
	// }, []);

	useEffect(() => {
		console.log(userOnboardingMessage);
		if (userOnboardingLoading === false) {
			if (
				userOnboardingMessage &&
				userOnboardingMessage.data &&
				userOnboardingMessage.data.id &&
				userOnboardingMessage.data.subdomain
			) {
				history.push('/my/userdashboard');
			}
			if (userOnboardingError) {
				console.log(userOnboardingError);
			}
		}
	}, [userOnboardingLoading, userOnboardingMessage, userOnboardingError]);

	/* added*/
	// useEffect(() => {
	// 	const token = getAccessToken();

	// 	const fetchData = async () => {
	// 		const response = await fetch(`${API_ROOT}partner-onboarding`, {
	// 			method: 'GET',
	// 			headers: {
	// 				'Content-Type': 'application/json',
	// 				authorization: `Bearer ${token.token}`,
	// 			},
	// 		});
	// 		const data = await response.json();
	// 		if (data !== undefined) {
	// 			setName(data?.data.partner_name);
	// 			setPhone(data?.data.partner_phone);
	// 			setCity(data?.data.partner_city);
	// 			setZIP(data?.data.partner_PIN);
	// 			setState(data?.data.partner_state);
	// 			setSelectedValue(data?.data.partner_shop_network);
	// 			setAbout(data?.data.partner_about);
	// 			setUniqueCode(data?.data.partner_code);

	// 			/*

	//             const res1 = await data.data.partner_name;
	//             const res2 = await data.data.partner_phone;
	//             const res3 = await data.data.partner_city;
	//             const res4 = await data.data.partner_PIN;
	//             const res5 = await data.data.partner_state;
	//             const res6 = await data.data.partner_shop_network;
	//             const res7 = await data.data.partner_code;

	//             if(res1 && res2 && res3 && res4 && res5 && res6 && res7)
	//             await dispatch
	//             {
	//                 console.log(res5);
	//                 setShowAlert(true);
	//             }

	//             */
	// 		}
	// 	};
	// 	fetchData();
	// }, []);

	// useEffect(() => {
	// 	uniqueCodeValidation();
	// }, [uniqueCode]);

	const phoneValidation = () => {
		let result = true;
		if (!phone) {
			setIsPhoneEmpty(true);
			result = false;
		} else {
			setIsPhoneEmpty(false);
			if (/\D/.test(phone)) {
				setIsPhoneValidate(false);
				result = false;
			} else {
				setIsPhoneValidate(true);
				if (phone.length !== 10) {
					setIsPhoneValidate(false);
					result = false;
				} else setIsPhoneValidate(true);
			}
		}
		return result;
	};

	// const storeIdValidation = () => {
	//     let result = true;
	//     if (!storeType) {
	//         setIsBusinessTypeEmpty(true);
	//         result = false;
	//     } else {
	//         if (!storeType.id) {
	//             setIsBusinessTypeEmpty(true);
	//             result = false;
	//         }
	//     }
	//     return result;
	// };

	const nameValidation = () => {
		let result = true;
		if (!name) {
			setIsNameEmpty(true);
			result = false;
		} else {
			setIsNameEmpty(false);
			if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~01234567899]/.test(name)) {
				setIsNameValidate(false);
				result = false;
			} else setIsNameValidate(true);
		}
		return result;
	};

	/* added */
	const onSelectChange = async (e: any) => {
		setSelectedValue(e.detail.value);
	};

	const cityValidation = () => {
		let result = true;
		if (!city) {
			setIsCityEmpty(true);
			result = false;
		} else {
			setIsCityEmpty(false);
		}
		return result;
	};
	const stateValidation = () => {
		let result = true;
		if (!state) {
			setIsStateEmpty(true);
			result = false;
		} else {
			setIsStateEmpty(false);
		}
		return result;
	};
	const selectedValueValidation = () => {
		let result = true;
		if (!selectedValue) {
			setIsSelectedValueEmpty(true);
			result = false;
		} else {
			setIsSelectedValueEmpty(false);
		}
		return result;
	};
	const zipValidation = () => {
		let result = true;
		if (!ZIP) {
			setIsZipEmpty(true);
			result = false;
		} else {
			setIsZipEmpty(false);
			if (/\D/.test(ZIP)) {
				setIsZipValidate(false);
				result = false;
			} else {
				setIsZipValidate(true);
				if (ZIP.length > 6) {
					setIsZipValidate(false);
					result = false;
				} else setIsZipValidate(true);
			}
		}
		return result;
	};
	const generateRandomCode = () => {
		setUniqueCode(Math.random().toString(36).slice(2, 7).toUpperCase());
	};

	// const uniqueCodeValidation = async () => {
	// 	const accessToken = await getAccessToken();
	// 	let result = true;
	// 	setIsUniqueCodeValidate(true);
	// 	setIsUniqueCodeAvailable(true);
	// 	if (!uniqueCode) {
	// 		setIsUniqueCodeEmpty(true);
	// 		result = false;
	// 	} else {
	// 		setIsUniqueCodeEmpty(false);
	// 		if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(uniqueCode)) {
	// 			setIsUniqueCodeValidate(false);
	// 			result = false;
	// 		} else {
	// 			setIsUniqueCodeValidate(true);
	// 			if (uniqueCode.length !== 5) {
	// 				setIsUniqueCodeValidate(false);
	// 				result = false;
	// 			} else {
	// 				setIsUniqueCodeValidate(true);
	// 				const response = await fetch(
	// 					`${API_ROOT}profile/unique-partner-code/${uniqueCode}`,
	// 					{
	// 						method: 'GET',
	// 						headers: {
	// 							'Content-Type': 'application/json',
	// 							authorization: `Bearer ${accessToken.token}`,
	// 						},
	// 					}
	// 				);
	// 				const message = await response.json();
	// 				let isAvailable = message.data.result === 'available' ? true : false;
	// 				if (isAvailable === false) {
	// 					setIsUniqueCodeAvailable(false);
	// 					result = false;
	// 				} else setIsUniqueCodeAvailable(true);
	// 			}
	// 		}
	// 	}
	// 	return result;
	// };
	/* end added */

	// const businessURLValidation = async (accessToken: string) => {
	//     let result = true;
	//     if (!businessURL) {
	//         setIsBusinessURLEmpty(true);
	//         result = false;
	//     } else {
	//         setIsBusinessURLEmpty(false);
	//         if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(businessURL)) {
	//             setIsBusinessURLValidate(false);
	//             result = false;
	//         } else {
	//             setIsBusinessURLValidate(true);
	//             const response = await fetch(
	//                 `https://api.intelikart.com/api/subdomain-availability/${businessURL}.intelikart.com`,
	//                 {
	//                     method: "GET",
	//                     headers: {
	//                         "Content-Type": "application/json",
	//                         authorization: `${accessToken}`,
	//                     },
	//                 }
	//             );
	//             const message = await response.json();
	//             let isAvailable =
	//                 message.data.result === "available" ? true : false;
	//             if (isAvailable === false) {
	//                 setIsBusinessURLAvailable(false);
	//                 result = false;
	//             } else setIsBusinessURLAvailable(true);
	//         }
	//     }
	//     return result;
	// };

	const submitHandler = async (e: any) => {
		e.preventDefault();
		setLoading(true);
		const accessToken = await getAccessToken();
		const res1 = await phoneValidation();
		const res2 = await nameValidation();
		const res3 = await cityValidation();
		const res4 = await stateValidation();
		const res5 = await zipValidation();
		// const res6 = await uniqueCodeValidation();
		const res7 = await selectedValueValidation();
		// if (res1 && res2 && res3 && res4 && res5 && res6 && res7) {
		// 	// await dispatch(
		// 	// 	onboardingUser(accessToken.token, {
		// 	// 		partner_name: `${name}`,
		// 	// 		partner_phone: `${phone}`,
		// 	// 		partner_city: `${city}`,
		// 	// 		partner_state: `${state}`,
		// 	// 		partner_PIN: `${ZIP}`,
		// 	// 		partner_shop_network: `${selectedValue}`,
		// 	// 		partner_about: `${about}`,
		// 	// 		partner_code: `${uniqueCode}`,
		// 	// 		id: `${accessToken.profile.id}`,
		// 	// 	})
		// 	// );
		// }
		setLoading(false);
		setShowAlert(true);
	};

	const firstNameInputRef = useRef<HTMLIonInputElement>(null);
	useIonViewDidEnter(() => {
		firstNameInputRef.current?.setFocus();
	});

	let classOfGrid = '';
	if (isDesktop) {
		classOfGrid = 'center-grid-top-0';
	}

	let isEnabled =
		name &&
		phone &&
		city &&
		state &&
		ZIP &&
		selectedValue &&
		uniqueCode &&
		isUniqueCodeAvailable &&
		isUniqueCodeValidate;

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/getbusinessdetails" />
					</IonButtons>
					{/* <IonTitle>Enter Business Details</IonTitle> */}
					<IonTitle>Partner Onboarding</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-padding ion-text-center">
				<IonGrid className={classOfGrid}>
					<IonRow>
						<IonCol size="12">
							<div className="iklogo">
								<LogoImage />
							</div>
							<IonTitle className="ion-padding ion-text-center">
								Please enter your details
							</IonTitle>
							<form className="ion-padding" onSubmit={submitHandler}>
								<IonItem className="ion-margin-bottom">
									<IonLabel position="stacked">Enter Name</IonLabel>
									<IonInput
										placeholder="Name"
										type="text"
										value={name}
										name="name"
										onIonChange={e => {
											setName(e.detail.value);
										}}
										ref={firstNameInputRef}
									/>
								</IonItem>
								<IonText
									color="danger"
									className={`${
										isNameEmpty === false && isNameValidate === true
											? 'ion-hide'
											: ''
									}`}
								>
									<h5
										style={{
											fontSize: 12 + 'px',
											marginTop: 0 + 'px',
										}}
										className={`ion-margin-horizontal ${
											isNameEmpty ? '' : 'ion-hide'
										}`}
									>
										Name is required.
									</h5>
									<h5
										style={{
											fontSize: 12 + 'px',
											marginTop: 0 + 'px',
										}}
										className={`ion-margin-horizontal ${
											isNameValidate ? 'ion-hide' : ''
										}`}
									>
										Name can't contain special characters or numbers.
									</h5>
								</IonText>
								<IonItem className="ion-margin-bottom">
									<IonLabel position="stacked">Enter Mobile No. </IonLabel>
									{/* <IonInput ref={firstNameInputRef} placeholder="Mobile Number" type="tel" inputmode="numeric" value={phone} name="phone" onIonChange={(e)=>{ setPhone(e.detail.value)}}/> */}
									<IonInput
										placeholder="Mobile Number"
										type="tel"
										inputmode="numeric"
										value={phone}
										name="phone"
										onIonChange={e => {
											setPhone(e.detail.value);
										}}
									/>
								</IonItem>
								<IonText
									color="danger"
									className={`${
										isPhoneEmpty === false && isPhoneValidate === true
											? 'ion-hide'
											: ''
									}`}
								>
									<h5
										style={{
											fontSize: 12 + 'px',
											marginTop: 0 + 'px',
										}}
										className={`ion-margin-horizontal ${
											isPhoneEmpty ? '' : 'ion-hide'
										}`}
									>
										Mobile number is required.
									</h5>
									<h5
										style={{
											fontSize: 12 + 'px',
											marginTop: 0 + 'px',
										}}
										className={`ion-margin-horizontal ${
											isPhoneValidate ? 'ion-hide' : ''
										}`}
									>
										Mobile number is incorrect.
									</h5>
								</IonText>
								<IonItem className="ion-margin-bottom">
									<IonLabel position="stacked">Enter City</IonLabel>
									<IonInput
										type="text"
										placeholder="City"
										value={city}
										name="city"
										onIonChange={e => {
											setCity(e.detail.value);
										}}
									/>
								</IonItem>
								<IonText
									className={`${isCityEmpty ? '' : 'ion-hide'}`}
									color="danger"
								>
									<h5
										className="ion-margin-horizontal"
										style={{
											fontSize: 12 + 'px',
											marginTop: 0 + 'px',
										}}
									>
										City is required.
									</h5>
								</IonText>
								<IonItem className="ion-margin-bottom">
									<IonLabel position="stacked">Enter State</IonLabel>
									<IonInput
										type="text"
										placeholder="State"
										value={state}
										name="state"
										onIonChange={e => {
											setState(e.detail.value);
										}}
									/>
								</IonItem>
								<IonText
									className={`${isStateEmpty ? '' : 'ion-hide'}`}
									color="danger"
								>
									<h5
										className="ion-margin-horizontal"
										style={{
											fontSize: 12 + 'px',
											marginTop: 0 + 'px',
										}}
									>
										State is required.
									</h5>
								</IonText>
								<IonItem className="ion-margin-bottom">
									<IonLabel position="stacked">Enter ZIP</IonLabel>
									<IonInput
										placeholder="ZIP"
										type="text"
										value={ZIP}
										name="PIN"
										onIonChange={e => {
											setZIP(e.detail.value);
										}}
									/>
								</IonItem>
								<IonText
									color="danger"
									className={`${
										isZipEmpty === false && isZipValidate === true
											? 'ion-hide'
											: ''
									}`}
								>
									<h5
										style={{
											fontSize: 12 + 'px',
											marginTop: 0 + 'px',
										}}
										className={`ion-margin-horizontal ${
											isZipEmpty ? '' : 'ion-hide'
										}`}
									>
										ZIP is required.
									</h5>
									<h5
										style={{
											fontSize: 12 + 'px',
											marginTop: 0 + 'px',
										}}
										className={`ion-margin-horizontal ${
											isZipValidate ? 'ion-hide' : ''
										}`}
									>
										ZIP must contains numbers only and of maximum 6 digits.
									</h5>
								</IonText>

								{/* <IonItem className="ion-margin-bottom">
                                    <IonLabel position="stacked">Select Your Business Type</IonLabel>
                                    <div style={{width: 100+'%'}} onClick={e=> setShowModal(true)}>
                                        <IonGrid className="ion-no-padding ion-padding-top">
                                            <IonRow className="ion-align-items-center">
                                                <IonCol className="ion-no-padding" size="9">
                                                    { storeType && storeType.id && storeType.icon && storeType.name && storeType.description ? <> */}
								{/* <IonImg slot="start" src={storeType.icon} style={{width: 50+'px', height: 50+'px'}}/> */}
								{/* <IonText>
                                                            <p className="storeTypeName">{storeType.name}</p>
                                                        </IonText> */}
								{/* <IonInput value={storeType.name} readonly></IonInput>
                                                    </> : <IonInput placeholder="Type of Business" readonly></IonInput>}
                                                </IonCol>
                                                <IonCol size="3" className="ion-no-padding">
                                                    <IonItem lines="none"><IonIcon slot="end" icon={caretDownIcon}/></IonItem>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </div>
                                    <IonModal isOpen={showModal} onDidDismiss={e=>setShowModal(false)} >
                                        <IonHeader>
                                            <IonItem lines="none">
                                                <IonTitle>Select Business Type</IonTitle>
                                                <IonButton fill='clear' size='large'  slot='end' onClick={(e) => setShowModal(false)} >
                                                <IonIcon slot="icon-only" icon={closeOutline}  ></IonIcon> 
                                                </IonButton> */}
								{/* <IonText style={{cursor:'pointer'}}  color="primary" slot="end" onClick={e=> setShowModal(false)}>
                                                    <h5>CLOSE</h5>
                                                </IonText> */}
								{/* </IonItem>
                                        </IonHeader>
                                        <IonContent className="ion-padding">
                                        {businessTypeList && businessTypeList.data ? 
                                            (
                                                businessTypeList.data.map((element:businesstypeItem) =>(
                                                    <IonItem key={element.id} style={{cursor:'pointer'}} onClick={e=>{setStoreType(element); setShowModal(false)}}>
                                                        <IonImg slot="start" src={element.icon} style={{width: 50+'px', height: 50+'px'}}/>
                                                        <IonText>
                                                            <h5>{element.name}</h5>
                                                            <p className="ion-no-margin ion-margin-bottom">{element.description}</p>
                                                        </IonText>
                                                        {storeType && storeType.id && element.id === storeType.id ? <IonIcon slot="end" color="primary" size="large" icon={checkmarkSharpIcon}/> : ""}
                                                    </IonItem>
                                                )))
                                            : null
                                        }
                                        </IonContent>
                                    </IonModal> */}
								{/* <IonSelect placeholder="Type of Business" onIonChange={(e) => { setStoreType(e.detail.value) }}>
                                        {businessTypeList && businessTypeList.data ? 
                                            (
                                                businessTypeList.data.map((element:businesstypeItem) =>(
                                                        <IonSelectOption key={element.id} value={element.id}>{element.name}</IonSelectOption>
                                                )))
                                            : null
                                        }
                                    </IonSelect> */}
								{/* </IonItem>
                                <IonText color="danger" className={`${isBusinessTypeEmpty ? "": "ion-hide" }`}><h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className="ion-margin-horizontal">Business Type can't be blank.</h5></IonText> */}

								<IonItem lines="none" className="ion-margin-bottom">
									<IonText>
										How many customers do you have in your network?{' '}
									</IonText>
									<IonSelect
										slot="end"
										onIonChange={e => onSelectChange(e)}
										value={selectedValue}
										name="shops_network"
									>
										<IonSelectOption value="1-10">1-10</IonSelectOption>
										<IonSelectOption value="10-50">10-50</IonSelectOption>
										<IonSelectOption value="50-100">50-100</IonSelectOption>
										<IonSelectOption value="100-500">100-500</IonSelectOption>
										<IonSelectOption value="500+">500+</IonSelectOption>
									</IonSelect>
								</IonItem>
								<IonText
									className={`${isSelectedValueEmpty ? '' : 'ion-hide'}`}
									color="danger"
								>
									<h5
										className="ion-margin-horizontal"
										style={{
											fontSize: 12 + 'px',
											marginTop: 0 + 'px',
										}}
									>
										Shops network is required.
									</h5>
								</IonText>

								<IonItem className="ion-margin-bottom">
									<IonLabel position="stacked">
										Enter About You (Optional)
									</IonLabel>
									<IonTextarea
										placeholder="About You"
										value={about}
										onIonChange={event => setAbout(event.detail.value)}
									/>
								</IonItem>

								{/* <IonItem className="ion-margin-bottom">
                                    <IonLabel position="stacked">Business Name</IonLabel>
                                    <IonInput placeholder="App name / Business name" type="text" value={name} name="name" onIonChange={(e)=>{ setName(e.detail.value);  setBusinessURL(e.detail.value ?(e.detail.value).replace(/ /g,'').toUpperCase() : e.detail.value) }}/>
                                </IonItem>
                                <IonText color="danger" className={`${isNameEmpty === false && isNameValidate === true ? "ion-hide": "" }`}>
                                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isNameEmpty ? "" : "ion-hide"}`}>Business name is required.</h5>
                                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isNameValidate ? "ion-hide" : ""}`}>Business name can't contain special characters.</h5>
                                </IonText> */}

								{/* <IonItem className="ion-margin-bottom" lines="none">
                                    <IonLabel position="stacked">Choose A URL For Store</IonLabel>
                                    <br/>
                                    <IonItem className="ion-no-padding" style={{width: 100+'%'}}>
                                        <IonInput type="text" value={businessURL} name="businessURL" onIonChange={(e)=>{ setBusinessURL(e.detail.value.toUpperCase())}}/>
                                        <IonText item-right>.intelikart.com</IonText>
                                    </IonItem>
                                </IonItem>
                                <IonText color="danger" className={`${isBusinessURLEmpty === false && isBusinessURLValidate === true && isBusinessURLAvailable === true ? "ion-hide": "" }`}>
                                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isBusinessURLEmpty ? "" : "ion-hide"}`}>Business URL is required.</h5>
                                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isBusinessURLValidate ? "ion-hide" : ""}`}>Business URL can't contain special characters.</h5>
                                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isBusinessURLAvailable ? "ion-hide" : ""}`}>This business URL isn't available.</h5>
                                </IonText> */}

								<IonItem className="ion-margin-bottom">
									<IonGrid
										className="ion-no-padding ion-no-margin"
										style={{ width: 100 + '%' }}
									>
										<IonRow className="ion-align-items-center">
											<IonCol size="8">
												<IonLabel position="stacked">
													Enter Your Unique Code
												</IonLabel>
											</IonCol>
											<IonCol className="ion-padding-top">
												<IonText
													color="primary"
													onClick={e => generateRandomCode()}
													style={{
														cursor: 'pointer',
													}}
												>
													GENERATE
												</IonText>
											</IonCol>
										</IonRow>
									</IonGrid>

									<IonInput
										placeholder="PICYH"
										type="text"
										value={uniqueCode}
										name="uniqueCode"
										onIonChange={e => {
											setUniqueCode(e.detail.value?.toUpperCase());
										}}
									/>
								</IonItem>
								<IonText
									color="danger"
									className={`${
										isUniqueCodeEmpty === false &&
										isUniqueCodeValidate === true &&
										isUniqueCodeAvailable === true
											? 'ion-hide'
											: ''
									}`}
								>
									<h5
										style={{
											fontSize: 12 + 'px',
											marginTop: 0 + 'px',
										}}
										className={`ion-margin-horizontal ${
											isUniqueCodeEmpty ? '' : 'ion-hide'
										}`}
									>
										Unique Code is required.
									</h5>
									<h5
										style={{
											fontSize: 12 + 'px',
											marginTop: 0 + 'px',
										}}
										className={`ion-margin-horizontal ${
											isUniqueCodeValidate ? 'ion-hide' : ''
										}`}
									>
										Unique Code can't contain special characters and length
										should be 5.
									</h5>
									<h5
										style={{
											fontSize: 12 + 'px',
											marginTop: 0 + 'px',
										}}
										className={`ion-margin-horizontal ${
											isUniqueCodeAvailable ? 'ion-hide' : ''
										}`}
									>
										This Unique Code isn't available.
									</h5>
								</IonText>

								<IonButton expand="block" type="submit" disabled={!isEnabled}>
									Finish
								</IonButton>
							</form>
							<IonAlert
								isOpen={showAlert}
								onDidDismiss={() => setShowAlert(false)}
								message={
									'Thank you for sharing your details, partner onboarding team will contact you soon.'
								}
								buttons={[
									{
										text: 'OK',
										handler: () => history.push('/logout'),
									},
								]}
							/>

							<IonLoading
								isOpen={loading === true}
								message={'Please Wait...'}
							/>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
			{/* <LoginFooter/> */}
		</IonPage>
	);
};

export default GetBusinessDetails;
