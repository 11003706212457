import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { IonContent, IonPage,IonButton, IonCard,IonItem, IonIcon,IonText,IonCol,IonRow,IonGrid } from '@ionic/react';
import TipBanners from '../components/dashboard/TipBanners';
import HomeScreen from '../components/dashboard/HomeScreen';
import Title from '../components/dashboard/Header';
import './Dashboard.css';
import { RouteComponentProps, withRouter, useLocation } from 'react-router';
import { logoWhatsapp ,call, addOutline } from 'ionicons/icons';

const Dashboard: React.FC = () => {
	const [count, setCount] = useState<any>();

	useEffect(() => {
		if (sessionStorage.getItem('reloadCount')) {
			setCount(sessionStorage.getItem('reloadCount'));
		} else {
			sessionStorage.setItem('reloadCount', String(1));
			setCount('1');
		}
	}, []);

	useEffect(() => {
		if (count === '1') {
			sessionStorage.setItem('reloadCount', String(10));
			window.location.reload();
		}
	}, [count]);


	const sendMessage = (phoneNumber:any, businessName:any) => {
		const message = `Hi, can you please pickup the laundry today?`;
		// const message = `${businessName} PLEASE send the pickup today.`; // %0A represents line break, %2C represents comma

		const encodedMessage = encodeURIComponent(message);
		return `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
	  };
	  
	  // Usage:
	  const phoneNumber = "+918445081734";
	  const businessName = "Your Business Name";



	  const [loaded, setLoaded] = useState(false);
    const val = "Help&Support";

    useEffect(() => {
        setLoaded(true); // Set loaded to true once the component mounts
    }, []); // Empty dependency array ensures the effect runs only once

    // Function to handle opening WhatsApp chat with a delay
    const openWhatsAppWithDelay = () => {
        setTimeout(() => {
            window.location.href = 'https://wa.me/+918445081734?text=Hello%0A%20there!%20I%20need%20help.';
        }, 1000); // 1000 milliseconds delay (adjust as needed)
    };

    // Render the component conditionally based on the loaded state
    if (!loaded) {
        return <div>Loading...</div>;
    }
	  
	return (
		<IonPage className="">
			<div className="ion-page" id="main-content">
				<Title name="Home" />
				<IonContent className="ion-padding">
					{/* <HomeScreen /> */}
					{/* <TipBanners/>  added*/}

                    {/* <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonCard onClick={openWhatsAppWithDelay} style={{ cursor: "pointer" }}>
                                    <IonItem lines="none">
                                        <IonIcon slot="start" icon={logoWhatsapp} size="large" style={{ color: "green" }} />
                                        <IonText>
                                            Request Pickup
                                        </IonText>
                                    </IonItem>
                                </IonCard>
                            </IonCol>
                            <IonCol>
                                <IonCard style={{ cursor: "pointer" }}>
                                    <IonItem lines="none">
                                        <IonIcon slot="start" icon={call} size="large" style={{ color: "blue" }} />
                                        <IonText onClick={() => window.location.href = 'tel:+918445081734'}>
                                            +918445081734
                                        </IonText>
                                    </IonItem>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid> */}

<IonGrid>

        <IonRow>

        
{/* <IonCol className=" ion-text-end" >
   
    <Link to={`orders/create`}>
								<IonButton shape="round">
									<IonIcon icon={addOutline} />
									Create Order
								</IonButton>
							</Link>
    
							
</IonCol> */}



    </IonRow> 

    <IonRow>
   
   
<IonCol>
    <IonCard href={sendMessage(phoneNumber, businessName)} target="_blank">
  <IonItem lines="none">
    <IonIcon slot="start" icon={logoWhatsapp} size="large" style={{ color: "green" }} />
    <IonText> Request Pickup</IonText>
  </IonItem>
</IonCard> 
        </IonCol>
        </IonRow>
</IonGrid>
				</IonContent>
			</div>
		</IonPage>
	);
};

export default Dashboard;
