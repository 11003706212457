// import { IonContent, IonHeader, IonText, IonRow, IonCol, IonPage, IonTitle, IonToolbar, IonButtons, IonMenuButton, useIonViewDidLeave, IonButton, IonIcon, IonSearchbar, IonCardHeader, IonCard, IonCardTitle, IonCardContent, IonItem, IonLabel, IonChip, IonAvatar, IonImg, useIonAlert, useIonToast, IonRefresher, IonRefresherContent, IonSkeletonText, IonModal, IonSegment, IonSegmentButton, IonDatetime, IonBadge, IonTabBar, IonTabButton, IonGrid, IonRouterOutlet,IonLoading } from '@ionic/react';
// import './tab.css'
// import { close, triangle, square } from 'ionicons/icons';
// import { trashBinOutline, chevronForwardOutline } from 'ionicons/icons';
// import React, { useEffect, useState } from 'react';
// // import { useState, useRef } from 'react';
// import Title from '../dashboard/Header';
// import { Redirect, Route, Link } from 'react-router-dom';

// import { API_ROOT } from '../../constants/global';
// import { getAccessToken } from '../../services/auth/userLocalStorage';

// import {
// 	addOutline as addOutlineIcon,
// } from 'ionicons/icons';
// // import AddOrder from '../../pages/AddOrder';
// {/* <IonIcon slot="icon-only" icon={close} color={'light'}></IonIcon> */ }
// // this is for selected orders

// const Tab1: React.FC = () => {
//   const [loading, setLoading] = useState(false);

//     const [orders, setOrders] = useState([]);
   
//     const formatDate = (
//       dateTimeString: string,
//       format: Intl.DateTimeFormatOptions
//     ): string => {
//       const formattedDate = new Date(dateTimeString).toLocaleString(
//         "en-IN",
//         format
//       );
//       const capitalizedAM = formattedDate
//         .replace(/-/, " ")
//         .replace(/\b(am|pm)\b/g, (match) => match.toUpperCase());
//       return capitalizedAM.replace(/(\b[a-zA-Z]{3})-(\d{2})/, "$1 $2");
//     };
//     function formatStatus(status: string): string {
//       return status.replace(/_/g, ' ');
//   }
  
//   // Example usage:
 
  



//     const fetchOrders = async () => {
//       setLoading(true);
//       try {
//         const res = await fetch('https://api.citylinencare.com/orders', {
//           method: 'GET',
//           headers: {
//             'content-type': 'application/json',
//             authorization: `Bearer ${getAccessToken().token}`,
//           },
//         });
    
//         if (!res.ok) {
//           throw new Error(`Failed to fetch orders. Status: ${res.status}`);
//         }
    
//         const responseData = await res.json();
    
//         if (responseData && responseData.data && responseData.data.length > 0) {
//           // Filter orders based on specific statuses
//           const filteredOrders = responseData.data.filter(order =>
//             ['NEW', 'DELIVERED',].includes(order.status)
//           );
    
//           setOrders(filteredOrders);
//           console.log('orderss', filteredOrders);
//         } else {
//           console.log('No orders found in the response:', responseData);
//         }
//       } catch (error) {
//         console.error('Error fetching orders:', error.message);
//       } finally {
//         setLoading(false);
//       }
//     };
    
//     useEffect(() => {
//       if (!localStorage.getItem('reload')) { 
//         localStorage['reload'] = true; 
//         console.log("object created")
        
//       } 
//       fetchOrders();
      
//     }, []);

   

//     return (
//         <IonPage>
//             { // @ts-ignore
//                 // @ts-nocheck 
//                 <Title name="Orders">

//                 </Title>}
//             <IonContent>
//             <><IonGrid>
//                     <IonRow>
//                     <IonCol className="ion-text-center">
						
//               <h6>These Orders Need Your Actions</h6>
// 						</IonCol>
//                     </IonRow>
//                 </IonGrid>
//       {orders.map((order) => (
//         <div key={order.id}>
//           <IonCard key={order.id}>
//             <IonItem lines="none">
//               <IonText slot="start">
//                 <h5>Order #{order.id}</h5>
//               </IonText>
//               <IonText slot="end">
//               <h5>{formatDate(order.created_at, {
//                               day: "2-digit",
//                               month: "short",
//                               year: "2-digit",
//                               hour: "numeric",
//                               minute: "2-digit",
//                               hour12: true,
//                             })}</h5>
//               </IonText>
//             </IonItem>
//             {order.business && order.business.logo_display_url && (
//             <IonItem lines="none">
//             <IonImg
//                   slot="start"
//                   src={order.business.logo_display_url}
//                   style={{ width: '14%', height: '15vh' }}
//                 />
//                 <IonLabel slot="start">
//                   <IonText style={{textWrap:"wrap"}}>
//                     <p >Business: {order.business.name}</p>
//                     <p>Products: {order.order_products.length}</p>
//                     <p>Items: {order.order_products.reduce((total, product) => total + product.count_pickup, 0)}</p>
//                   </IonText>
//                 </IonLabel>
//               </IonItem>
//             )}
//             <IonItem lines="none">
//               <IonText slot="start">
//                 <b>{formatStatus(order.status)}</b>
//               </IonText>
//               <IonItem lines="none" slot="end" className="ion-no-padding">
//                 <Link to={{ pathname: `/orders/${order.id}`, state: order.id }}>
//                   <IonButton shape='round'>Details</IonButton>
//                 </Link>
//               </IonItem>
//             </IonItem>
//           </IonCard>
//         </div>
//       ))}


//     </>
//     <IonLoading
//         isOpen={loading}
//         message={'Please wait...'}
//       /></IonContent>
//   <IonTabBar slot="bottom">
//             <IonTabButton tab="tab1" href="/app/orders/act">
//                           <IonIcon icon={triangle}/>
//                           <IonLabel>
//                             ACT 
//                           </IonLabel>
                          
//             </IonTabButton>

//             <IonTabButton tab="tab2" href="/app/orders/all">
//                           <IonIcon icon={square} />
//                           <IonLabel>
//                             ALL 
//                           </IonLabel>
                          
//             </IonTabButton>
//            </IonTabBar>
//         </IonPage>
//     );
// };

// export default Tab1;
import { IonContent, IonHeader, IonText, IonRow, IonCol, IonPage, IonTitle, IonToolbar, IonButtons, IonMenuButton, useIonViewDidLeave, IonButton, IonIcon, IonSearchbar, IonCardHeader, IonCard, IonCardTitle, IonCardContent, IonItem, IonLabel, IonChip, IonAvatar, IonImg, useIonAlert, useIonToast, IonRefresher, IonRefresherContent, IonSkeletonText, IonModal, IonSegment, IonSegmentButton, IonDatetime, IonBadge, IonTabBar, IonTabButton, IonGrid, IonRouterOutlet,IonLoading } from '@ionic/react';
import './tab.css'
import { close, triangle, square } from 'ionicons/icons';
import { trashBinOutline, chevronForwardOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
// import { useState, useRef } from 'react';
import Title from '../dashboard/Header';
import { Redirect, Route, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { API_ROOT } from '../../constants/global';
import { getAccessToken } from '../../services/auth/userLocalStorage';

// import { formatDate } from '../../services/formatters';
import {
	addOutline as addOutlineIcon,
} from 'ionicons/icons';
import AddOrder from '../../pages/AddOrder';
import { getOrders } from '../../actions/orderActions';



const Tab2: React.FC = () => {
  const [loading, setLoading] = useState(false);

    const [orderx, setOrderx] = useState([]);

const [ordert,setOrdert] =useState([])

    const { ordersLoading, orders, ordersError } = useSelector(
      (state: any) => state.orders
    );
  
    const dispatch=useDispatch();
    useEffect(() => {
          dispatch(getOrders(getAccessToken().token))
      }, []);

    const formatDate = (
        dateTimeString: string,
        format: Intl.DateTimeFormatOptions
      ): string => {
        const formattedDate = new Date(dateTimeString).toLocaleString(
          "en-IN",
          format
        );
        const capitalizedAM = formattedDate
          .replace(/-/, " ")
          .replace(/\b(am|pm)\b/g, (match) => match.toUpperCase());
        return capitalizedAM.replace(/(\b[a-zA-Z]{3})-(\d{2})/, "$1 $2");
      };

      function formatStatus(status: string): string {
        return status.replace(/_/g, ' ');
    }
    // const fetchOrders = async () => {
    //   setLoading(true);

    //     try {
    //         const res = await fetch('https://api.citylinencare.com/orders', {
    //             method: 'GET',
    //             headers: {
    //                 'content-type': 'application/json',
    //                 authorization: `Bearer ${getAccessToken().token}`,
    //             },
    //         });

    //         if (!res.ok) {
    //             throw new Error(`Failed to fetch orders. Status: ${res.status}`);
    //         }

    //         const responseData = await res.json();

    //         if (responseData && responseData.data && responseData.data.length > 0) {
    //             setOrderx(responseData.data);
    //             // console.log('ordersx', responseData.data);
    //         } else {
    //             console.log('No orders found in the response:', responseData);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching orders:', error.message);
    //     }finally {
    //       setLoading(false);
    //     }
    // };

    // useEffect(() => {
    //     fetchOrders();
    // }, []);


   
   
    return (
        <IonPage>
            { // @ts-ignore
                // @ts-nocheck 
                <Title name="Orders">

                </Title>}


            <IonContent>

                <>
                <IonGrid>
                    <IonRow>
                    <IonCol className="ion-text-center">
							<h6>These Orders Need Your Actions</h6>
						</IonCol>
                    </IonRow>
                </IonGrid>

                    {orders.map((order) => (
                      (order?.status == "NEW" || order?.status == "DELIVERED") &&
                       ( <div key={order.id}>
                            <IonCard key={order.id}>
                                <IonItem lines="none">
                                    <IonText slot="start">
                                        <h5>Order #{order.id}</h5>
                                    </IonText>
                                    <IonText slot="end">
                                        <h5>{formatDate(order.created_at, {
                              day: "2-digit",
                              month: "short",
                              year: "2-digit",
                              hour: "numeric",
                              minute: "2-digit",
                              hour12: true,
                            })}</h5>
                                    </IonText>
                                </IonItem>
                                {order.business && order.business.logo_display_url && (
                                    <IonItem lines="none">
                                        <IonImg
                                            slot="start"
                                            src={order.business.logo_display_url}
                                            style={{ width: '15%', height: '15vh' }}
                                        />
                                        <IonLabel slot="start">
                                            <IonText style={{textWrap:"wrap"}}>
                                                <p >Business: {order.business.name}</p>
                                                <p>Products: {order.order_products.length}</p>
                                                <p>Items: {order.order_products.reduce((total, product) => total + product.count_pickup, 0)}</p>
                                            </IonText>
                                        </IonLabel>
                                    </IonItem>
                                )}
                                <IonItem lines="none">
                                    <IonText slot="start">
                                        <b>{formatStatus(order.status)}</b>
                                    </IonText>
                                    <IonItem lines="none" slot="end" className="ion-no-padding">
                                    <div className="ion-text-center">

        

      
{(order) && (  <><Link to={{ pathname: `/orders/${order.id}`, state: order.id }}>
                                            <IonButton shape='round'>Details</IonButton>
                                        </Link>
                                    </>
                                        
                                        )}
                                        </div>
                                    </IonItem>
                                </IonItem>
                            </IonCard>
                        </div>)
                    ))}
                          

                </> 
                 <IonLoading
        isOpen={loading}
        message={'Please wait...'}
      />
            </IonContent>
            <IonTabBar slot="bottom">
            <IonTabButton tab="tab1" href="/app/orders/act">
                          <IonIcon icon={triangle}/>
                          <IonLabel>
                            ACT 
                          </IonLabel>
                          
            </IonTabButton>

            <IonTabButton tab="tab2" href="/app/orders/all">
                          <IonIcon icon={square} />
                          <IonLabel>
                            ALL 
                          </IonLabel>
                          
            </IonTabButton>
           </IonTabBar>
        </IonPage>
    );
};

export default Tab2;