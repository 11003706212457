import React from 'react';
import { IonToolbar, IonFooter } from '@ionic/react';

const LoginFooter = () => {
	return (
		<IonFooter class=" ion-text-center">
			<IonToolbar>
				<h5
					style={{
						fontSize: 14 + 'px',
						paddingLeft: 10 + '%',
						paddingRight: 10 + '%',
					}}
				>
					Copyright &copy; CLC
				</h5>
			</IonToolbar>
		</IonFooter>
	);
};

export default LoginFooter;
