export const LOAD_TIPS_BANNERS_REQUEST = 'LOAD_TIPS_BANNERS_REQUEST'
export const LOAD_TIPS_BANNERS_SUCCESS = 'LOAD_TIPS_BANNERS_SUCCESS'
export const LOAD_TIPS_BANNERS_FAIL = 'LOAD_TIPS_BANNERS_FAIL'

export const LOAD_HOME_SCREEN_REQUEST = 'LOAD_HOME_SCREEN_REQUEST'
export const LOAD_HOME_SCREEN_SUCCESS = 'LOAD_HOME_SCREEN_SUCCESS'
export const LOAD_HOME_SCREEN_FAIL = 'LOAD_HOME_SCREEN_FAIL'

export const LOAD_HOME_SCREEN_WITH_DAYS_REQUEST = 'LOAD_HOME_SCREEN_WITH_DAYS_REQUEST'
export const LOAD_HOME_SCREEN_WITH_DAYS_SUCCESS = 'LOAD_HOME_SCREEN_WITH_DAYS_SUCCESS'
export const LOAD_HOME_SCREEN_WITH_DAYS_FAIL = 'LOAD_HOME_SCREEN_WITH_DAYS_FAIL'

export const LOAD_ACADEMY_CONTENT_REQUEST = 'LOAD_ACADEMY_CONTENT_REQUEST'
export const LOAD_ACADEMY_CONTENT_SUCCESS = 'LOAD_ACADEMY_CONTENT_SUCCESS'
export const LOAD_ACADEMY_CONTENT_FAIL = 'LOAD_ACADEMY_CONTENT_FAIL'

export const LOAD_CUSTOMERS_REQUEST = 'LOAD_CUSTOMERS_REQUEST'
export const LOAD_CUSTOMERS_SUCCESS = 'LOAD_CUSTOMERS_SUCCESS'
export const LOAD_CUSTOMERS_FAIL = 'LOAD_CUSTOMERS_FAIL'

export const GET_COUPONS_REQUEST = 'GET_COUPONS_REQUEST'
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS'
export const GET_COUPONS_FAIL = 'GET_COUPONS_FAIL'

export const CREATE_COUPON_REQUEST = 'CREATE_COUPON_REQUEST'
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS'
export const CREATE_COUPON_FAIL = 'CREATE_COUPON_FAIL'

export const DELETE_COUPON_REQUEST = 'DELETE_COUPON_REQUEST'
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS'
export const DELETE_COUPON_FAIL = 'DELETE_COUPON_FAIL'

export const GET_BANNERS_REQUEST = 'GET_BANNERS_REQUEST'
export const GET_BANNERS_SUCCESS = 'GET_BANNERS_SUCCESS'
export const GET_BANNERS_FAIL = 'GET_BANNERS_FAIL'

export const CREATE_BANNER_REQUEST = 'CREATE_BANNER_REQUEST'
export const CREATE_BANNER_SUCCESS = 'CREATE_BANNER_SUCCESS'
export const CREATE_BANNER_FAIL = 'CREATE_BANNER_FAIL'

export const DELETE_BANNER_REQUEST = 'DELETE_BANNER_REQUEST'
export const DELETE_BANNER_SUCCESS = 'DELETE_BANNER_SUCCESS'
export const DELETE_BANNER_FAIL = 'DELETE_BANNER_FAIL'

export const GET_SETTINGS_BASIC_INFO_REQUEST = 'GET_SETTINGS_BASIC_INFO_REQUEST'
export const GET_SETTINGS_BASIC_INFO_SUCCESS = 'GET_SETTINGS_BASIC_INFO_SUCCESS'
export const GET_SETTINGS_BASIC_INFO_FAIL = 'GET_SETTINGS_BASIC_INFO_FAIL'

export const SET_SETTINGS_BASIC_INFO_REQUEST = 'SET_SETTINGS_BASIC_INFO_REQUEST'
export const SET_SETTINGS_BASIC_INFO_SUCCESS = 'SET_SETTINGS_BASIC_INFO_SUCCESS'
export const SET_SETTINGS_BASIC_INFO_FAIL = 'SET_SETTINGS_BASIC_INFO_FAIL'

export const GET_SETTINGS_LOCALIZATION_REQUEST = 'GET_SETTINGS_LOCALIZATION_REQUEST'
export const GET_SETTINGS_LOCALIZATION_SUCCESS = 'GET_SETTINGS_LOCALIZATION_SUCCESS'
export const GET_SETTINGS_LOCALIZATION_FAIL = 'GET_SETTINGS_LOCALIZATION_FAIL'

export const SET_SETTINGS_LOCALIZATION_REQUEST = 'SET_SETTINGS_LOCALIZATION_REQUEST'
export const SET_SETTINGS_LOCALIZATION_SUCCESS = 'SET_SETTINGS_LOCALIZATION_SUCCESS'
export const SET_SETTINGS_LOCALIZATION_FAIL = 'SET_SETTINGS_LOCALIZATION_FAIL'

export const GET_SETTINGS_SERVICE_DETAILS_REQUEST = 'GET_SETTINGS_SERVICE_DETAILS_REQUEST'
export const GET_SETTINGS_SERVICE_DETAILS_SUCCESS = 'GET_SETTINGS_SERVICE_DETAILS_SUCCESS'
export const GET_SETTINGS_SERVICE_DETAILS_FAIL = 'GET_SETTINGS_SERVICE_DETAILS_FAIL'

export const SET_SETTINGS_SERVICE_DETAILS_REQUEST = 'SET_SETTINGS_SERVICE_DETAILS_REQUEST'
export const SET_SETTINGS_SERVICE_DETAILS_SUCCESS = 'SET_SETTINGS_SERVICE_DETAILS_SUCCESS'
export const SET_SETTINGS_SERVICE_DETAILS_FAIL = 'SET_SETTINGS_SERVICE_DETAILS_FAIL'

export const GET_SETTINGS_QUICK_ORDERS_REQUEST = 'GET_SETTINGS_QUICK_ORDERS_REQUEST'
export const GET_SETTINGS_QUICK_ORDERS_SUCCESS = 'GET_SETTINGS_QUICK_ORDERS_SUCCESS'
export const GET_SETTINGS_QUICK_ORDERS_FAIL = 'GET_SETTINGS_QUICK_ORDERS_FAIL'

export const SET_SETTINGS_QUICK_ORDERS_REQUEST = 'SET_SETTINGS_QUICK_ORDERS_REQUEST'
export const SET_SETTINGS_QUICK_ORDERS_SUCCESS = 'SET_SETTINGS_QUICK_ORDERS_SUCCESS'
export const SET_SETTINGS_QUICK_ORDERS_FAIL = 'SET_SETTINGS_QUICK_ORDERS_FAIL'

export const GET_SETTINGS_PAYMENTS_REQUEST = 'GET_SETTINGS_PAYMENTS_REQUEST'
export const GET_SETTINGS_PAYMENTS_SUCCESS = 'GET_SETTINGS_PAYMENTS_SUCCESS'
export const GET_SETTINGS_PAYMENTS_FAIL = 'GET_SETTINGS_PAYMENTS_FAIL'

export const SET_SETTINGS_PAYMENTS_REQUEST = 'SET_SETTINGS_PAYMENTS_REQUEST'
export const SET_SETTINGS_PAYMENTS_SUCCESS = 'SET_SETTINGS_PAYMENTS_SUCCESS'
export const SET_SETTINGS_PAYMENTS_FAIL = 'SET_SETTINGS_PAYMENTS_FAIL'

export const GET_TAXES_REQUEST = 'GET_TAXES_REQUEST'
export const GET_TAXES_SUCCESS = 'GET_TAXES_SUCCESS'
export const GET_TAXES_FAIL = 'GET_TAXES_FAIL'

export const CREATE_TAX_REQUEST = 'CREATE_TAX_REQUEST'
export const CREATE_TAX_SUCCESS = 'CREATE_TAX_SUCCESS'
export const CREATE_TAX_FAIL = 'CREATE_TAX_FAIL'

export const DELETE_TAX_REQUEST = 'DELETE_TAX_REQUEST'
export const DELETE_TAX_SUCCESS = 'DELETE_TAX_SUCCESS'
export const DELETE_TAX_FAIL = 'DELETE_TAX_FAIL'

export const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_FAIL = 'UPLOAD_IMAGE_FAIL'

export const GET_STAFFS_REQUEST = 'GET_STAFFS_REQUEST'
export const GET_STAFFS_SUCCESS = 'GET_STAFFS_SUCCESS'
export const GET_STAFFS_FAIL = 'GET_STAFFS_FAIL'

export const CREATE_STAFF_REQUEST = 'CREATE_STAFF_REQUEST'
export const CREATE_STAFF_SUCCESS = 'CREATE_STAFF_SUCCESS'
export const CREATE_STAFF_FAIL = 'CREATE_STAFF_FAIL'

export const DELETE_STAFF_REQUEST = 'DELETE_STAFF_REQUEST'
export const DELETE_STAFF_SUCCESS = 'DELETE_STAFF_SUCCESS'
export const DELETE_STAFF_FAIL = 'DELETE_STAFF_FAIL'

export const GET_PRINTABLES_REQUEST = 'GET_PRINTABLES_REQUEST'
export const GET_PRINTABLES_SUCCESS = 'GET_PRINTABLES_SUCCESS'
export const GET_PRINTABLES_FAIL = 'GET_PRINTABLES_FAIL'

export const GET_ANNOUNCEMENTS_REQUEST = 'GET_ANNOUNCEMENTS_REQUEST'
export const GET_ANNOUNCEMENTS_SUCCESS = 'GET_ANNOUNCEMENTS_SUCCESS'
export const GET_ANNOUNCEMENTS_FAIL = 'GET_ANNOUNCEMENTS_FAIL'

export const CREATE_ANNOUNCEMENT_REQUEST = 'CREATE_ANNOUNCEMENT_REQUEST'
export const CREATE_ANNOUNCEMENT_SUCCESS = 'CREATE_ANNOUNCEMENT_SUCCESS'
export const CREATE_ANNOUNCEMENT_FAIL = 'CREATE_ANNOUNCEMENT_FAIL'

export const GET_ORDERS_BY_STATUS_REQUEST = 'GET_ORDERS_BY_STATUS_REQUEST'
export const GET_ORDERS_BY_STATUS_SUCCESS = 'GET_ORDERS_BY_STATUS_SUCCESS'
export const GET_ORDERS_BY_STATUS_FAIL = 'GET_ORDERS_BY_STATUS_FAIL'

export const GET_ORDER_BY_ID_REQUEST = 'GET_ORDER_BY_ID_REQUEST'
export const GET_ORDER_BY_ID_SUCCESS = 'GET_ORDER_BY_ID_SUCCESS'
export const GET_ORDER_BY_ID_FAIL = 'GET_ORDER_BY_ID_FAIL'

export const GET_INVOICE_REQUEST = 'GET_INVOICE_REQUEST'
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS'
export const GET_INVOICE_FAIL = 'GET_INVOICE_FAIL'

export const ORDER_STATUS_CHANGE_REQUEST = 'ORDER_STATUS_CHANGE_REQUEST'
export const ORDER_STATUS_CHANGE_SUCCESS = 'ORDER_STATUS_CHANGE_SUCCESS'
export const ORDER_STATUS_CHANGE_FAIL = 'ORDER_STATUS_CHANGE_FAIL'

export const CHANGE_ORDER_STAFF_REQUEST = 'CHANGE_ORDER_STAFF_REQUEST'
export const CHANGE_ORDER_STAFF_SUCCESS = 'CHANGE_ORDER_STAFF_SUCCESS'
export const CHANGE_ORDER_STAFF_FAIL = 'CHANGE_ORDER_STAFF_FAIL'

export const GET_ADJUSTMENTS_REQUEST = 'GET_ADJUSTMENTS_REQUEST'
export const GET_ADJUSTMENTS_SUCCESS = 'GET_ADJUSTMENTS_SUCCESS'
export const GET_ADJUSTMENTS_FAIL = 'GET_ADJUSTMENTS_FAIL'

export const SET_ADJUSTMENTS_REQUEST = 'SET_ADJUSTMENTS_REQUEST'
export const SET_ADJUSTMENTS_SUCCESS = 'SET_ADJUSTMENTS_SUCCESS'
export const SET_ADJUSTMENTS_FAIL = 'SET_ADJUSTMENTS_FAIL'

export const EDIT_ADJUSTMENTS_REQUEST = 'EDIT_ADJUSTMENTS_REQUEST'
export const EDIT_ADJUSTMENTS_SUCCESS = 'EDIT_ADJUSTMENTS_SUCCESS'
export const EDIT_ADJUSTMENTS_FAIL = 'EDIT_ADJUSTMENTS_FAIL'

export const DELETE_ADJUSTMENTS_REQUEST = 'DELETE_ADJUSTMENTS_REQUEST'
export const DELETE_ADJUSTMENTS_SUCCESS = 'DELETE_ADJUSTMENTS_SUCCESS'
export const DELETE_ADJUSTMENTS_FAIL = 'DELETE_ADJUSTMENTS_FAIL'

export const LOAD_PRODUCTS_BY_SUBCATEGORY_REQUEST = 'LOAD_PRODUCTS_BY_SUBCATEGORY_REQUEST'
export const LOAD_PRODUCTS_BY_SUBCATEGORY_SUCCESS = 'LOAD_PRODUCTS_BY_SUBCATEGORY_SUCCESS'
export const LOAD_PRODUCTS_BY_SUBCATEGORY_FAIL = 'LOAD_PRODUCTS_BY_SUBCATEGORY_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const GET_LANDING_PAGES_REQUEST = 'GET_LANDING_PAGES_REQUEST'
export const GET_LANDING_PAGES_SUCCESS = 'GET_LANDING_PAGES_SUCCESS'
export const GET_LANDING_PAGES_FAIL = 'GET_LANDING_PAGES_FAIL'

export const SET_LANDING_PAGES_REQUEST = 'SET_LANDING_PAGES_REQUEST'
export const SET_LANDING_PAGES_SUCCESS = 'SET_LANDING_PAGES_SUCCESS'
export const SET_LANDING_PAGES_FAIL = 'SET_LANDING_PAGES_FAIL'

export const GET_SUBDOMAIN_AVAILABILITY_REQUEST = 'GET_SUBDOMAIN_AVAILABILITY_REQUEST'
export const GET_SUBDOMAIN_AVAILABILITY_SUCCESS = 'GET_SUBDOMAIN_AVAILABILITY_SUCCESS'
export const GET_SUBDOMAIN_AVAILABILITY_FAIL = 'GET_SUBDOMAIN_AVAILABILITY_FAIL'

export const GET_REPORTS_CLICK_REQUEST = 'GET_REPORTS_CLICK_REQUEST'
export const GET_REPORTS_CLICK_SUCCESS = 'GET_REPORTS_CLICK_SUCCESS'
export const GET_REPORTS_CLICK_FAIL = 'GET_REPORTS_CLICK_FAIL'

export const SET_CLAIM_STORE='SET_CLAIM_STORE'
export const CREATE_CLAIM_SUCCESS = 'CREATE_CLAIM_SUCCESS'
export const CREATE_CLAIM_FAIL = 'CREATE_CLAIM_FAIL'
