import React, { useEffect, useState } from 'react';
import { IonContent, IonCard, IonPage, IonGrid, IonCol, IonRow, IonIcon, IonItem, IonText } from '@ionic/react';
import { logoWhatsapp, call } from 'ionicons/icons';
import Title from "../dashboard/Header";

const HelpendSupport: React.FC = () => {
    const [loaded, setLoaded] = useState(false);
  console.log("hello")
  const val="Help&Support"
    useEffect(() => {
        
        setLoaded(true); // Set loaded to true once the component mounts
    }, []); // Empty dependency array ensures the effect runs only once

    // Render the component conditionally based on the loaded state
    if (!loaded) {
        return <div>Loading...</div>;
    }

    return (<div>
   
    <IonPage>
    <Title name="Help & Support" />
            <IonContent className="ion-padding">
            
                <IonGrid>
                    <IonRow>
                        <IonCol>
                             <IonCard href='https://wa.me/+918445081734' target="_blank">
                                <IonItem lines="none" >
                                    <IonIcon slot="start" icon={logoWhatsapp} size="large" style={{ color: "green" }} />
                                    <IonText>
                                        Chat With Us
                                    </IonText>
                                </IonItem>
                            </IonCard>
                           

                        </IonCol>
                        <IonCol>
                            <IonCard style={{cursor: "pointer"}}>
                                <IonItem lines="none">
                                    <IonIcon slot="start" icon={call} size="large" style={{ color: "blue" }} />
                                    <IonText onClick={() => window.location.href = 'tel:+918445081734'}>
                                        +918445081734
                                    </IonText>
                                </IonItem>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    </div>
        
    );
};

export default HelpendSupport;
