import React, { useState, useEffect } from 'react';
import { API_ROOT } from '../../constants/global';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import Title from "../dashboard/Header";
import OrderState from "./orderState";

import orderContext from "./orderContext";
import {
    IonInput,
    IonContent,
    IonPage,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonLabel,
    IonItem,
    IonAccordionGroup,
    IonThumbnail,
    IonImg,
    IonList,
    IonButton,
    IonChip,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCol,
    IonRow,
    IonToast,
    IonAlert,
    IonTitle,
    IonAccordion
  } from "@ionic/react";
  import './AddOrder.css'
const AddOrder: React.FC = () => {
    const [nameInput,setNameInput]= useState<any>('');
    const [countInput,setCountInput]= useState<any>('');
    const [businesses, setBusinesses] = useState<any[]>([]);
    const [selectedBusinessId, setSelectedBusinessId] = useState<number | null>(null);
    const [orders, setOrders] = useState<any[]>([]);

    useEffect(() => {
        const tokenObject = getAccessToken();
        const fetchBusinesses = async () => {
            try {
                const response = await fetch(`${API_ROOT}order-init/businesses`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${tokenObject.token}`,
                    },
                });
                const data = await response.json();
                if (data.data) {
                    setBusinesses(data.data);
                }
            } catch (error) {
                console.error('Error fetching businesses:', error);
            }
        };

        fetchBusinesses();
    }, []);

    const fetchOrders = async (businessId: number) => {
        try {
          const response = await fetch(`${API_ROOT}order-init/products?businessId=${businessId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getAccessToken().token}`,
            },
          });
          const data = await response.json();
          if (data.data) {
            setOrders(data.data);
          }
        } catch (error) {
          console.error('Error fetching orders:', error);
        }
      };

/////////////////////////

const handleSave = async () => {
    if (!nameInput || !countInput || !selectedBusinessId) {
        // Handle validation here, display error messages or prevent saving
        return;
    }

    const payload = {
        business_id: selectedBusinessId,
        weight_at_pickup_gms: 0, // Not sure where this value comes from in your logic
        order_products: [
            {
                id: 0, // Not sure where this value comes from in your logic
                count_pickup: countInput,
                notes_pickup: nameInput
            }
        ]
    };

    try {
        const response = await fetch(`${API_ROOT}/orders`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getAccessToken().token}`,
            },
            body: JSON.stringify(payload)
        });
        // Assuming the response indicates success
        window.location.reload(); // Reload the page upon successful save
    } catch (error) {
        console.error('Error saving order:', error);
        // Handle error appropriately (display error message, etc.)
    }
};
//////////////////
    const handleBusinessChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedBusinessId(parseInt(event.target.value));
    };
    
  const handleBusinessClick = (businessId: number) => {
    setSelectedBusinessId(businessId);
    fetchOrders(businessId);
  };

    return (
        
        <OrderState>
      <IonPage>
        <Title name="AddOrder" />
        <IonContent>
          
            
<div className='accord'>
<IonAccordionGroup>
      <IonAccordion value="first"  className='mainaccord'>
        <IonItem slot="header" color="primary" >
          <IonLabel style={{textWrap:'wrap',}}>  <h2 >Business Name
            </h2>
        {/* <h2>Business Name
            </h2> */}
            </IonLabel>

        </IonItem>
        
        {businesses.map((business) => (

<h4  key={business.id} className="ion-no-padding inside" slot="content" onClick={() => handleBusinessClick(business.id)} style={{cursor:'pointer',borderBottom:'1px solid black',borderRadius:'2px',padding:'0px',
// textWrap:'wrap'
}}>
{business.name}
</h4>
   ))}
       
        
       

      </IonAccordion>
    </IonAccordionGroup>
</div>

          <IonCard>
   
   <IonCardContent>
   
    <IonLabel>

        {/* <h1 style={{color:'black'}} >Orders for Selected Business</h1> */}
        
    </IonLabel>
    

   
            {orders.map((order,index) => (
                <div key={order.id}>
                    <IonList>
                         <IonItem>
                         <IonThumbnail className="ion-margin-start">
                          <IonImg src={order.image_display_url} />
                        </IonThumbnail>

                            


  <IonLabel >
   <h4 style={{marginLeft:'22vw'}}  className='hideOnMobile' >{order.name}
    </h4> 
  {  // @ts-ignore
<h4 className='showOnMobile ion-padding' style={{textWrap:'wrap'}}> {order.name}</h4>}
  </IonLabel>
                            

                        <IonLabel>
                            <h4 className='hideOnMobile' style={{marginLeft:'20vw'}}>
                                {order.id}
                            </h4>
                            <h4 className='showOnMobile'>
                            {order.id}
                            </h4>
                            
                        </IonLabel>
                         </IonItem>
                     </IonList>
                </div>
                
              
            ))}
       
       <IonLabel className='ion-no-padding'>
        <h1>Add New Orders</h1>
        
       </IonLabel>
<IonList className='ion-no-padding'>
       <IonItem className='ion-padding' lines='inset' >
         <div style={{display:"flex",flexWrap:"wrap",justifyContent:'center',alignItems:'center'}}>
         <IonLabel>
        
        { // @ts-ignore

              <input type="file" id="myfile" name="myfile" placeholder='enter your image'  style={{textWrap:'wrap'}}/>}

              </IonLabel>
              <IonLabel className='ion-margin-start' style={{
               
              }}>
                           <IonItem>
                            <IonLabel position="floating">
                                 Enter item name
                            </IonLabel>
                            <IonInput
                            value={nameInput} onIonChange={(e:any)=>setNameInput(e.target.value)}>
                                      
                            </IonInput>
                           </IonItem>
              </IonLabel>
              <IonLabel>
              <IonItem  style={{marginLeft:'6.5vw'}}>
                            <IonLabel position="floating">
                                 Enter item count
                            </IonLabel>
                            <IonInput
                            value={countInput} onIonChange={(e:any)=>setCountInput(e.target.value)}>
                                      
                            </IonInput>
                           </IonItem>
              </IonLabel>
         </div>
            
              
       </IonItem>

</IonList>

   </IonCardContent>

          </IonCard>

          <IonRow>
                    <IonCol className="ion-text-center">
                      <IonButton
                        shape="round" onClick={handleSave}>
                            Save
                        </IonButton>
                        </IonCol>
                        </IonRow>
</IonContent>
</IonPage>
        </OrderState>
    );
};

export default AddOrder;
