import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar,IonButtons,IonMenuButton, IonTabs,IonRouterOutlet,IonTabBar,IonIcon,IonTabButton,IonLabel} from '@ionic/react';
import React from 'react';
import { triangle, square } from 'ionicons/icons';

import { Redirect, Route } from 'react-router-dom';
import Tab1 from './tab1';
import Tab2 from './tab2';

const Tab: React.FC = () => {

    return (
        <IonTabs>
          


           <IonRouterOutlet>
            <Route  path="/app/orders/act" component={Tab1}/>
            <Route  path="/app/orders/all" component={Tab2}/>
            
            <Route exact path="/app/orders">
                   <Redirect to="/app/orders/act"/>
            </Route>
           </IonRouterOutlet>
            </IonTabs>
    );
};

export default Tab;