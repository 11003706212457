import {
	GET_ORDER_BY_ID_FAIL,
	GET_ORDER_BY_ID_REQUEST,
	GET_ORDER_BY_ID_SUCCESS,
} from '../constants/dashboardConstants';
import { API_ROOT } from '../constants/global';
import {
	ACTION_UPDATE_ORDER_FAIL,
	ACTION_UPDATE_ORDER_REQUEST,
	ACTION_UPDATE_ORDER_SUCCESS,
	GET_ORDERS_FAIL,
	GET_ORDERS_REQUEST,
	GET_ORDERS_SUCCESS,
} from '../constants/orderConstants';

export const getOrders = (accessToken: string) => async (dispatch: any) => {
	try {
		dispatch({ type: GET_ORDERS_REQUEST });

		const response = await fetch(`${API_ROOT}orders`, {
			method: 'GET',
			headers: {
				'content-type': 'application/json',
				authorization: `Bearer ${accessToken}`,
			},
		});
		const data = await response.json();
		console.log("data in action,api is called",data);
		if (data?.statusCode === 500) {
			dispatch({
				type: GET_ORDERS_FAIL,
				payload: data,
			});
		} else {
			dispatch({
				type: GET_ORDERS_SUCCESS,
				payload: data.data,
			});
		}
	} catch (error) {
		dispatch({
			type: GET_ORDERS_FAIL,
			payload: error,
		});
	}
};

//Get Orders by id
export const getOrdersById =
	(accessToken: string, orderId: any) => async (dispatch: any) => {
		try {
			dispatch({ type: GET_ORDER_BY_ID_REQUEST });
			const response = await fetch(`${API_ROOT}orders/${orderId}`, {
				method: 'GET',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${accessToken}`,
				},
			});
			const data = await response.json();
			// console.log(data);
			if (data?.statusCode === 500) {
				dispatch({
					type: GET_ORDER_BY_ID_FAIL,
					payload: data,
				});
			} else {
				dispatch({
					type: GET_ORDER_BY_ID_SUCCESS,
					payload: data,
				});
			}
		} catch (error) {
			dispatch({
				type: GET_ORDER_BY_ID_FAIL,
				payload: error,
			});
		}
	};

//Update Orders by id

export const editOrderStatus =
	(accessToken: string, orderId: string, body) => async (dispatch: any) => {
		try {
			dispatch({ type: ACTION_UPDATE_ORDER_REQUEST });
			// console.log(body);
			const response = await fetch(`${API_ROOT}orders/${orderId}`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${accessToken}`,
				},
				body: JSON.stringify(body),
			});
			const data = await response.json();
			dispatch({
				type: ACTION_UPDATE_ORDER_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: ACTION_UPDATE_ORDER_FAIL,
				payload: error,
			});
		}
	};