import React, { useState, useEffect, useContext } from "react";
import {
  IonContent,
  IonPage,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonItem,
  IonThumbnail,
  IonImg,
  IonList,
  IonButton,
  IonChip,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCol,
  IonRow,
  IonToast,
  IonAlert,
  IonTitle,IonLoading, IonButtons, IonHeader, IonToolbar, IonGrid
} from "@ionic/react";
import "./modal.css"
import { useDispatch, useSelector } from "react-redux";

import { useParams, useHistory } from "react-router-dom";
import {
  fileTrayOutline,
  peopleOutline,
  cardOutline,
  addOutline,
  checkmarkOutline,
  closeOutline,
  arrowBackCircleOutline,
  arrowBackOutline
} from "ionicons/icons";
import Title from "../dashboard/Header";
// import OrderState from "./orderState";
// import orderContext from "./orderContext";
import { API_ROOT } from "../../constants/global";
import { getAccessToken } from "../../services/auth/userLocalStorage";
import { stat } from "fs";
import { getOrders } from "../../actions/orderActions";

interface Business {
  id: number;
  user_id: number;
  name: string;
  logo: string;
  logo_display_url: string;
}

interface OrderProduct {
  id: number;
  order_id: number;
  business_product_id: number;
  business_id: number;
  name: string;
  image: string;
  image_display_url: string;
  weight_in_gms: number;
  laundry_service_price: number;
  dimension: string;
  help_text: string;
  count_pickup: number;
  notes_pickup: string;
}

interface Order {
  id: number;
  business_id: number;
  created_by_user_id: number;
  status: string;
  notes: string | null;
  weight_at_pickup_gms: string;
  business: Business;
  order_products: OrderProduct[];
  created_at: string;
}

const OrderDetailsPage: React.FC = () => {
  const history = useHistory();
  const handleToastDismiss = () => {
    setToastMessage('');
    setShowToast(false);
    // history.push('/app/orders/act'); // Redirect to the same place
};
  const [toastMessage, setToastMessage] = useState('');

  // const { isTabBarHidden, handleTabButtonClick } = useContext(orderContext);
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<Order | null>(null); // Corrected type
  const [showToast, setShowToast] = useState<boolean>(false);
  const [statusChanged, setStatusChanged] = useState<boolean>(false); // Add state to track status change
  const [orderproducts,setOrderProducts]=useState([])

  const [showAlert, setShowAlert] = useState<{ 
    isOpen: boolean;
    title: string;
    message: string;
    buttons: { text: string; handler: () => void }[];
  }>({ isOpen: false, title: "", message: "", buttons: [] });
const [businessid,setBusinessId]=useState('0');



const dispatch = useDispatch();

const [updateFlag, setUpdateFlag] = useState(false);
const handleUpdateItem = () => {

  setLoading(true);
  dispatch(getOrders(getAccessToken().token));
  history.push("/app/orders/act");
  setLoading(false);
 
};

useEffect(() => {
  // Reset statusChanged when navigating to other orders
  setStatusChanged(false);
}, [id]);

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      try {
        const tokenObject = getAccessToken();
        const resData = await getUser(tokenObject);
        setBusinessId(resData.data.business_id)
        console.log("this is order",resData.data.order_products);
         setOrderProducts(resData.data.order_products)
        setOrder(resData.data);
        
      } catch (err) {
        console.log(err);
      }
      finally {
        setLoading(false);
    }
    };

    fetchData();
  }, [id]);

  const handleReceiveOrder = async () => {
    
    setLoading(true);
    try {
      const tokenObject = getAccessToken();
      const response = await fetch(`${API_ROOT}orders/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${tokenObject.token}`
        },
        body: JSON.stringify({
          bussiness_id:businessid,
          status: "RECEIVED",
          order_products:orderproducts
        })
      });
      console.log(response);
      if (response.ok) {
        const updatedOrderResponse = await getUser(tokenObject);
        setOrder(updatedOrderResponse.data);
        const resr= await response.json();
        if(updatedOrderResponse.data ){
          console.log(updatedOrderResponse);
          setToastMessage(resr.message);

          setShowToast(true);
          handleUpdateItem();

        }
        
        setStatusChanged(true); // Update status change state
      } else {
        console.error("Failed to update order:", response.statusText);
      }
    } catch (err) {
      console.error("Error updating order:", err);
    }
    finally {
      setLoading(false);
  }
  };
  function formatStatus(status: string): string {
    return status.replace(/_/g, ' ');
}

  const updateOrder = async () => {
    setLoading(true);

    try {
      const tokenObject = getAccessToken();
      const response = await fetch(`https://api.citylinencare.com/orders/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${tokenObject.token}`
        },
        body: JSON.stringify({
          bussiness_id:businessid,
          status: "APPROVED",
          order_products:orderproducts
        })
      });
      // console.log(response.json());
      if (response.ok) {
        const updatedOrderResponse = await getUser(tokenObject);
        const res= await response.json();
        console.log("this is reponse ",res)
        setOrder(updatedOrderResponse.data);
        if(updatedOrderResponse.data ){
          setToastMessage("Order Status Changed to APPROVED successfully");

          setShowToast(true);
          handleUpdateItem();
          // history.push("/app/orders/all")
        }
        setStatusChanged(true); // Update status change state
        setShowAlert({ 
          isOpen: false, 
          title: "", 
          message: "", 
          buttons: [] 
        }); // Close the alert after updating
      } else {
        console.error("Failed to update order:", response.statusText);
      }
    } catch (err) {
      console.error("Error updating order:", err);
    }finally {
      setLoading(false);
  }
  };

  const getUser = async (tokenObject: { token: string }) => {
    try {
      const response = await fetch(`${API_ROOT}orders/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${tokenObject.token}`
        }
      });
      const data = await response.json();
      console.log(data);
      return data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const formatDate = (
    dateTimeString: string,
    format: Intl.DateTimeFormatOptions
  ): string => {
    const formattedDate = new Date(dateTimeString).toLocaleString(
      "en-IN",
      format
    );
    const capitalizedAM = formattedDate
      .replace(/-/, " ")
      .replace(/\b(am|pm)\b/g, (match) => match.toUpperCase());
    return capitalizedAM.replace(/(\b[a-zA-Z]{3})-(\d{2})/, "$1 $2");
  };

  let numberOfElements: number = 0;
  let sumOfCountPickup: number = 0;

  if (order) {
    numberOfElements = order.order_products.length;
    sumOfCountPickup = order.order_products.reduce(
      (sum, product) => sum + product.count_pickup,
      0
    );
  }
  const val = `Order #${id}` +" Details";


  const handleConfirmReceive = () => {
    setShowAlert({
      isOpen: true,
      title: "Confirm Delivery",
      message: "I confirm that, I received all the products as per displayed count",
      buttons: [
        {
          text: "Cancel",
          handler: () => setShowAlert({ isOpen: false, title: "", message: "", buttons: [] })
        },
        {
          text: "OK",
          handler: handleReceiveOrder
        }
      ]
    });
  };
  

  return (
    // <OrderState>
      <IonPage>
      <IonHeader>
    <IonToolbar>
        <IonButtons slot="start">
            <IonIcon
                size="large"
                onClick={e => history.goBack()}
                icon={arrowBackOutline}
                style={{cursor:'pointer'}}
            />
        </IonButtons>
        <IonTitle> {val}</IonTitle>                </IonToolbar>
</IonHeader>
        <IonContent  className="showOnMobile " >
          
          <IonCard>
          <IonCardHeader>
								<IonRow className="ion-align-items-center">
									<IonCol sizeMd="2" size="2.7">
										<img
											src={order?.business.logo_display_url}
											alt="Business Logo"
											style={{
												height: '60px',
												width: '60px',
												borderRadius: '8px',
											}}
										/>
									</IonCol>
									<IonCol sizeMd="3" size="4">
										<IonItem lines="none">
											{/* <h4 style={{}}> */}
											{order?.business.name}
											{/* Order #{order.data.id} */}
											{/* </h4> */}
										</IonItem>
									</IonCol>
									<IonCol sizeMd="7" size="5.3">
										{order && (
											<div
												style={{
													textAlign: 'end',
												}}
											>
												<IonLabel className="">
													{/* <h1 
													style={{
														color: 'black',
														paddingTop: '10px',
													}}
												>*/}
													 {formatDate(order.created_at, {
                          day: "2-digit",
                          month: "short",
                          year: "2-digit",
                          hour: "numeric",
                          minute: "2-digit",
                          hour12: true
                        })}
													{/* </h1> */}
												</IonLabel>
											</div>
										)}
									</IonCol>
								</IonRow>
							</IonCardHeader>

          
            <IonCardContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  marginRight: "1vw"
                }}
              >
                {order  && (
                  <IonChip slot="end">
                    <div
                      style={{
                        width: "auto",
                        height: "auto",
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "14px",
                        marginLeft: "0"
                      }}
                    >
                      {formatStatus(order.status)}
                    </div>
                  </IonChip>
                )}
              </div>
              <hr />
              <IonList>
                <IonItem>
                  <IonLabel className="ion-margin-start">
                    S.No.
                  </IonLabel>
                  <IonLabel>
                    Item
                  </IonLabel>
                  <IonLabel>
                    Count
                  </IonLabel>
                </IonItem>
              </IonList>
              {order?.order_products &&
                order.order_products.map((product, index) => (
                  <div key={product.id}>
                    <IonList>
                      <IonItem>
                        <IonLabel className="ion-margin-start">
                          {index + 1}
                        </IonLabel>
                        {/* mobile */}
                        {/* <IonThumbnail className="ion-margin-end">
                          <IonImg src={product.image_display_url} />
                        </IonThumbnail> */}
                        <IonLabel className="ion-text-wrap ion-margin-start ion-padding-start">
                          {product.name}
                        </IonLabel>
                        <IonLabel style={{border:"0px solid black",paddingLeft:"35px"}} className="ion-padding-start ion-margin-start">{product.count_pickup}</IonLabel>
                      </IonItem>
                    </IonList>
                  </div>
                ))}
              <IonList>
                <IonItem>
                  <IonLabel className="" style={{marginLeft:"1.8vh"}} >
                    <h2>Total</h2>
                  </IonLabel>
                  <IonLabel className="ion-margin-start">
                    <h2 >{numberOfElements}</h2>
                  </IonLabel>
                  <IonLabel
                    className=""
                    
                  >
                    <h2 style={{ paddingLeft: "3.3vh" }}>{sumOfCountPickup}</h2>
                  </IonLabel>
                </IonItem>
              </IonList>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {order && order.status === "NEW"  && (
                  <IonRow>
                    <IonCol className="ion-text-center">
                      <IonButton
                        shape="round"
                        id="open-toast"
                        onClick={() =>
                          setShowAlert({
                            isOpen: true,
                            title: "Confirm Approval",
                            message: "I confirm that, I approve all the product counts",
                            buttons: [
                              {
                                text: "Cancel",
                                handler: () => setShowAlert({ isOpen: false, title: "", message: "", buttons: [] })
                              },
                              {
                                text: "Confirm",
                                handler: updateOrder
                              }
                            ]
                          })
                        }
                      >
                        Approve
                      </IonButton>
                    
                    </IonCol>
                  </IonRow>
                )}

                {order && order.status === "DELIVERED"  && (
                  <IonRow>
                    <IonCol className="ion-text-center">
                      <IonButton
                        shape="round"
                        id="open-toast"
                        onClick={handleConfirmReceive}
                      >
                        Receive
                      </IonButton>
                     
                    </IonCol>
                  </IonRow>
                )}
              </div>
            </IonCardContent>
          </IonCard>


        </IonContent>






        <IonContent  className="hideOnMobile ion-padding custom-ion-content"
  >
          
          <IonCard >


            <IonCardHeader>
								<IonRow className="ion-align-items-center">
									<IonCol sizeMd="2" size="2.7">
										<img
											src={order?.business.logo_display_url}
											alt="Business Logo"
											style={{
												height: '80px',
												width: '80px',
												borderRadius: '8px',
											}}
										/>
									</IonCol>
									<IonCol sizeMd="3" size="4">
										<IonItem lines="none">
											{/* <h4 style={{}}> */}
											{order?.business.name}
											{/* Order #{order.data.id} */}
											{/* </h4> */}
										</IonItem>
									</IonCol>
									<IonCol sizeMd="7" size="5.3">
										{order && (
											<div
												style={{
													textAlign: 'end',
												}}
											>
												<IonLabel className="">
													{/* <h1 
													style={{
														color: 'black',
														paddingTop: '10px',
													}}
												>*/}
												 {formatDate(order.created_at, {
                          day: "2-digit",
                          month: "short",
                          year: "2-digit",
                          hour: "numeric",
                          minute: "2-digit",
                          hour12: true
                        })}	
													{/* </h1> */}
												</IonLabel>
											</div>
										)}
									</IonCol>
								</IonRow>
							</IonCardHeader>

            <IonCardContent>
            <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  marginRight: "1vw"
                }}
              >
                {order  && (
                  <IonChip slot="end">
                    <div
                      style={{
                        width: "auto",
                        height: "auto",
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "2vh",
                        marginLeft: "0"
                      }}
                    >
                      {formatStatus(order.status)}
                    </div>
                  </IonChip>
                )}
              </div>
              <hr />
              <IonList>
                <IonItem>
                  <IonLabel className="ion-margin-start">
                    <h2>S.No.</h2>
                  </IonLabel>
                  <IonLabel>
                    <h2>Item</h2>
                  </IonLabel>
                  <IonLabel>
                    <h2>Count</h2>
                  </IonLabel>
                </IonItem>
              </IonList>
              {order?.order_products &&
                order.order_products.map((product, index) => (
                  <div key={product.id}>
                    <IonList>
                      <IonItem>
                        <IonLabel className="ion-margin-start">
                          {index + 1}
                        </IonLabel>
                        <IonThumbnail className="ion-margin-end">
                          <IonImg src={product.image_display_url} />
                        </IonThumbnail>
                        <IonLabel className="ion-text-wrap ion-margin-end">
                          {product.name}
                        </IonLabel>
                        <IonLabel>{product.count_pickup}</IonLabel>
                      </IonItem>
                    </IonList>
                  </div>
                ))}
              <IonList>
                <IonItem>
                  <IonLabel className="" style={{marginLeft:"0vh"}} >
                    <h2>Total</h2>
                  </IonLabel>
                  <IonLabel className="">
                    <h2 >{numberOfElements}</h2>
                  </IonLabel>
                  <IonLabel
                    className=""
                    
                  >
                    <h2 style={{ paddingLeft: "36px",border:"0px solid" }}>{sumOfCountPickup}</h2>
                  </IonLabel>
                </IonItem>
              </IonList>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {order && order.status === "NEW" && !statusChanged && (
                  <IonRow>
                    <IonCol className="ion-text-center">
                    <IonButton
  shape="round"
  id="open-toast"
  onClick={() =>
    setShowAlert({
      isOpen: true,
      title: "Confirm Approval",
      message: "I've verified all products and counts",
      buttons: [
        {
          text: "Cancel",
          handler: () => setShowAlert({ isOpen: false, title: "", message: "", buttons: [] })
        },
        {
          text: "Ok",
          handler: updateOrder
        }
      ]
    })
  }
>
  Approve
</IonButton>

                      {/* <IonToast
                        isOpen={showToast}
                        message="Order approved"
                        duration={5000}
                        onDidDismiss={() => setShowToast(false)}
                      /> */}
                    </IonCol>
                  </IonRow>
                )}

                {order && order.status === "DELIVERED" && !statusChanged && (
                  <IonRow>
                    <IonCol className="ion-text-center">
                      <IonButton
                        shape="round"
                        id="open-toast"
                        onClick={handleConfirmReceive}
                      >
                        Receive
                      </IonButton>
                      {/* <IonToast
                        isOpen={showToast}
                        message={toastMessage}
                        duration={5000}
                        onDidDismiss={handleToastDismiss} 
                      /> */}
                    </IonCol>
                  </IonRow>
                )} 
              </div>
            </IonCardContent>
          </IonCard>
          <div>
<IonLoading
        isOpen={loading}
        message={'Please wait...'}
      />
</div>
  <IonToast
					isOpen={showToast}
					message={toastMessage}
					onDidDismiss={handleToastDismiss} // Call handleToastDismiss when toast is dismissed

					duration={5000}
					position="top"
				></IonToast>

        </IonContent>
      

        <IonAlert
          isOpen={showAlert.isOpen}
          onDidDismiss={() => setShowAlert({ isOpen: false, title: "", message: "", buttons: [] })}
          header={showAlert.title}
          message={showAlert.message} 
          buttons={showAlert.buttons}
          cssClass="custom-ion-alert ion-padding"
          
        />
      </IonPage>
    // </OrderState>
  );
};

export default OrderDetailsPage;
