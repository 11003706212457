import {
	ACTION_UPDATE_ORDER_FAIL,
	ACTION_UPDATE_ORDER_REQUEST,
	ACTION_UPDATE_ORDER_SUCCESS,
	GET_ORDERS_FAIL,
	GET_ORDERS_REQUEST,
	GET_ORDERS_SUCCESS,
} from '../constants/orderConstants';

export const getOrdersReducer = (state = { orders: [] }, action: any) => {
	switch (action.type) {
		case GET_ORDERS_REQUEST:
			return {
				...state,
				ordersLoading: true,
				ordersError: null,
			};
		case GET_ORDERS_SUCCESS:
			return {
				...state,
				ordersLoading: false,
				orders: action.payload,
			};
		case GET_ORDERS_FAIL:
			return {
				...state,
				ordersLoading: false,
				ordersError: action.payload,
			};
		default:
			return state;
	}
};
export const updateOrdersReducer = (
	state = { updateOrderLoading: false, updateOrderMessage: null },
	action: any
) => {
	switch (action.type) {
		case ACTION_UPDATE_ORDER_REQUEST:
			return {
				...state,
				updateOrderLoading: true,
				updateOrderError: null,
			};
		case ACTION_UPDATE_ORDER_SUCCESS:
			return {
				...state,
				updateOrderLoading: false,
				updateOrderMessage: action.payload,
				// updateOrderMessage: { message: 'Order updated successfully' },
			};
		case ACTION_UPDATE_ORDER_FAIL:
			return {
				...state,
				updateOrderLoading: false,
				updateOrderError: action.payload,
			};
		default:
			return state;
	}
};