import React, { useEffect, useContext } from 'react';
import { IonRouterContext } from '@ionic/react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import {
	authenticateUserWhenLogout,
	getCurrentUser,
} from './actions/authActions';
import { getAccessToken } from './services/auth/userLocalStorage';

interface RedirectToLoginProps {
	setIsLoggedIn: Function;
	setUsername: Function;
}

const RedirectToLogin = () => {
	const ionRouterContext = useContext(IonRouterContext);
	const history = useHistory();
	const dispatch = useDispatch();
	useEffect(() => {
		fun();
	}, [ionRouterContext]);
	const fun = async () => {
		await dispatch(authenticateUserWhenLogout());
		await localStorage.clear();
		dispatch(getCurrentUser(getAccessToken()));
		ionRouterContext.push('/');
	};
	return null;
};

export default RedirectToLogin;
