import orderContext from "./orderContext";
import { useState } from "react";

const OrderState = (props) => {
    const [isTabBarHidden, setTabBarHidden] = useState(false);

    const handleTabButtonClick = (tabName) => {
        if (tabName === 'orders' || tabName === 'Orders') {
          setTabBarHidden(true);
        } 
        else if( tabName === 'products' ) {
          setTabBarHidden(false);
          console.log("set false");
        }
        else{
            setTabBarHidden(false);
        }
    };

    const contextValue = {
        isTabBarHidden,
        handleTabButtonClick
    };

    return (
        <orderContext.Provider value={contextValue}>
            {props.children}
        </orderContext.Provider>
    );
}

export default OrderState;
